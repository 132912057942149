// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as Pino from "../../../bindings/Pino.bs.js";
import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Regex from "../../../utils/Regex.bs.js";
import * as Theme from "../../../css/Theme.bs.js";
import * as React from "react";
import * as CmsImage from "../../CmsImage.bs.js";
import * as Js_string from "@rescript/std/lib/es6/js_string.js";
import Link from "next/link";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as IndexStyles from "./IndexStyles.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Modal$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Modal/Modal.bs.js";
import * as Vimeo$PrezzeeRibbon from "@prezzee/ribbon/src/bindings/Vimeo.bs.js";
import * as Button$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Button/Button.bs.js";
import * as Fresnel$PrezzeeRibbon from "@prezzee/ribbon/src/bindings/Fresnel/Fresnel.bs.js";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as KontentContextProvider from "./KontentContextProvider.bs.js";
import * as RichText$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RichText/RichText.bs.js";
import * as Modal__Close$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Modal/Modal__Close.bs.js";
import * as ThemeContext$PrezzeeRibbon from "@prezzee/ribbon/src/context/ThemeContext/ThemeContext.bs.js";
import * as Theme__Color$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Color.bs.js";
import * as LargeHeroTile$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/LargeHeroTile/LargeHeroTile.bs.js";
import * as SmallHeroTile$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/SmallHeroTile/SmallHeroTile.bs.js";
import * as TwoOneHeroTiles$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/TwoOneHeroTiles/TwoOneHeroTiles.bs.js";
import * as ReactFontawesome from "@fortawesome/react-fontawesome";
import * as Modal__Container$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Modal/Modal__Container.bs.js";
import * as Theme__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Responsive.bs.js";
import * as Theme__Typography$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Typography.bs.js";
import * as VimeoPlayer__Video$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/VimeoPlayer/VimeoPlayer__Video.bs.js";
import * as VimeoPlayer__Player$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/VimeoPlayer/VimeoPlayer__Player.bs.js";
import * as LargeHeroTile__CtaLine$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/LargeHeroTile/LargeHeroTile__CtaLine.bs.js";
import * as LargeHeroTile__Heading$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/LargeHeroTile/LargeHeroTile__Heading.bs.js";
import * as SmallHeroTile__CtaLine$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/SmallHeroTile/SmallHeroTile__CtaLine.bs.js";
import * as SmallHeroTile__Heading$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/SmallHeroTile/SmallHeroTile__Heading.bs.js";
import * as LargeHeroTile__BodyText$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/LargeHeroTile/LargeHeroTile__BodyText.bs.js";
import * as SmallHeroTile__BodyText$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/SmallHeroTile/SmallHeroTile__BodyText.bs.js";
import * as SmallHeroTile__CardImage$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/SmallHeroTile/SmallHeroTile__CardImage.bs.js";
import * as LargeHeroTile__Background$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/LargeHeroTile/LargeHeroTile__Background.bs.js";
import * as LargeHeroTile__Foreground$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/LargeHeroTile/LargeHeroTile__Foreground.bs.js";
import * as SmallHeroTile__Background$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/SmallHeroTile/SmallHeroTile__Background.bs.js";
import * as SmallHeroTile__Foreground$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/SmallHeroTile/SmallHeroTile__Foreground.bs.js";
import * as FaPlay from "@fortawesome/free-solid-svg-icons/faPlay";
import * as VimeoPlayer__MobileAwareVideo$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/VimeoPlayer/VimeoPlayer__MobileAwareVideo.bs.js";

function getBgImage(url) {
  return url + "?fm=png&auto=format";
}

function ctaImageContainer(url, tabletUrl, mobileUrl, bgColor) {
  return Curry._1(BsCss.style, {
              hd: BsCss.cursor("pointer"),
              tl: {
                hd: BsCss.width(BsCss.pct(100)),
                tl: {
                  hd: BsCss.borderRadius(BsCss.px(10)),
                  tl: {
                    hd: BsCss.display("inlineBlock"),
                    tl: {
                      hd: BsCss.position("relative"),
                      tl: {
                        hd: BsCss.margin("auto"),
                        tl: {
                          hd: BsCss.backgroundColor({
                                NAME: "hex",
                                VAL: bgColor
                              }),
                          tl: {
                            hd: BsCss.backgroundSize("contain"),
                            tl: {
                              hd: BsCss.backgroundImage({
                                    NAME: "url",
                                    VAL: url
                                  }),
                              tl: {
                                hd: BsCss.backgroundRepeat("noRepeat"),
                                tl: {
                                  hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.CategoryTiles.mobile, {
                                        hd: BsCss.important(BsCss.backgroundImage({
                                                  NAME: "url",
                                                  VAL: mobileUrl
                                                })),
                                        tl: /* [] */0
                                      }),
                                  tl: {
                                    hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.CategoryTiles.tablet, {
                                          hd: BsCss.important(BsCss.backgroundImage({
                                                    NAME: "url",
                                                    VAL: tabletUrl
                                                  })),
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function ctaImageAction(isInfo) {
  return Curry._1(BsCss.style, {
              hd: BsCss.paddingTop(isInfo ? BsCss.px(20) : BsCss.px(80)),
              tl: {
                hd: BsCss.paddingBottom(isInfo ? BsCss.px(30) : BsCss.px(0)),
                tl: {
                  hd: BsCss.paddingLeft(isInfo ? BsCss.px(67) : BsCss.px(0)),
                  tl: {
                    hd: BsCss.paddingRight(isInfo ? BsCss.px(67) : BsCss.px(0)),
                    tl: {
                      hd: BsCss.position(isInfo ? "relative" : "absolute"),
                      tl: {
                        hd: BsCss.left(BsCss.px(0)),
                        tl: {
                          hd: BsCss.bottom(BsCss.px(0)),
                          tl: {
                            hd: BsCss.margin("auto"),
                            tl: {
                              hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.CategoryTiles.mobile, {
                                    hd: BsCss.paddingLeft(isInfo ? BsCss.px(20) : BsCss.px(0)),
                                    tl: {
                                      hd: BsCss.paddingRight(isInfo ? BsCss.px(20) : BsCss.px(0)),
                                      tl: /* [] */0
                                    }
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var ctaVideoContainer = Curry._1(BsCss.style, {
      hd: BsCss.cursor("pointer"),
      tl: {
        hd: BsCss.width(BsCss.pct(100)),
        tl: {
          hd: BsCss.minHeight(BsCss.px(200)),
          tl: {
            hd: BsCss.borderRadius(BsCss.px(10)),
            tl: {
              hd: BsCss.display("inlineBlock"),
              tl: {
                hd: BsCss.position("relative"),
                tl: {
                  hd: BsCss.backgroundSize("contain"),
                  tl: {
                    hd: BsCss.margin("auto"),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var ctaVideoAction = Curry._1(BsCss.style, {
      hd: BsCss.paddingTop(BsCss.px(80)),
      tl: {
        hd: BsCss.position("absolute"),
        tl: {
          hd: BsCss.left(BsCss.px(0)),
          tl: {
            hd: BsCss.bottom(BsCss.px(40)),
            tl: {
              hd: BsCss.margin("auto"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var noImageContentContainer = Curry._1(BsCss.style, {
      hd: BsCss.display("flex"),
      tl: {
        hd: BsCss.flexDirection("row"),
        tl: {
          hd: BsCss.justifyContent("spaceBetween"),
          tl: {
            hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.CategoryTiles.mobile, {
                  hd: BsCss.flexDirection("column"),
                  tl: /* [] */0
                }),
            tl: {
              hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.CategoryTiles.tablet, {
                    hd: BsCss.flexDirection("column"),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

function noImageContentHead(textColor) {
  return Curry._1(BsCss.style, {
              hd: BsCss.selector("h1, h2, h3, h4, h5, h6, p", {
                    hd: BsCss.important(BsCss.color(textColor ? Theme.Color.white : "currentColor")),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            });
}

var noImageContentAction = Curry._1(BsCss.style, {
      hd: BsCss.paddingTop(BsCss.px(35)),
      tl: {
        hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.CategoryTiles.mobile, {
              hd: BsCss.paddingTop(BsCss.px(0)),
              tl: /* [] */0
            }),
        tl: {
          hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.CategoryTiles.tablet, {
                hd: BsCss.paddingTop(BsCss.px(0)),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var videoBannerLink = Curry._1(BsCss.style, {
      hd: BsCss.position("absolute"),
      tl: {
        hd: BsCss.top("zero"),
        tl: {
          hd: BsCss.left("zero"),
          tl: {
            hd: BsCss.zIndex(10),
            tl: {
              hd: BsCss.width(BsCss.pct(100)),
              tl: {
                hd: BsCss.height(BsCss.pct(100)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var backgroundImageStyle = Curry._1(BsCss.style, {
      hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.CategoryTiles.mobile, {
            hd: BsCss.height(BsCss.pct(100)),
            tl: /* [] */0
          }),
      tl: /* [] */0
    });

var transparentBannerImage = Curry._1(BsCss.style, {
      hd: BsCss.visibility("hidden"),
      tl: {
        hd: BsCss.width(BsCss.pct(100)),
        tl: {
          hd: BsCss.height(BsCss.pct(100)),
          tl: /* [] */0
        }
      }
    });

function backgroundStyle(url) {
  return Curry._1(BsCss.style, {
              hd: BsCss.backgroundImage({
                    NAME: "url",
                    VAL: url
                  }),
              tl: /* [] */0
            });
}

function mobileBackgroundStyle(url) {
  return Curry._1(BsCss.style, {
              hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.CategoryTiles.mobile, {
                    hd: BsCss.important(BsCss.backgroundImage({
                              NAME: "url",
                              VAL: url
                            })),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            });
}

function bgColorStyle(bgColor) {
  return Curry._1(BsCss.style, {
              hd: BsCss.unsafe("backgroundColor", bgColor),
              tl: /* [] */0
            });
}

var foregroundDark = Curry._1(BsCss.style, {
      hd: BsCss.color("currentColor"),
      tl: /* [] */0
    });

var foregroundLight = Curry._1(BsCss.style, {
      hd: BsCss.selector("h1, h2, h3, h4, h5, h6, p", {
            hd: BsCss.important(BsCss.color(Theme.Color.white)),
            tl: /* [] */0
          }),
      tl: /* [] */0
    });

function videoContainer(theme) {
  return Curry._1(BsCss.style, {
              hd: BsCss.position("absolute"),
              tl: {
                hd: BsCss.right(BsCss.zero),
                tl: {
                  hd: BsCss.top(BsCss.zero),
                  tl: {
                    hd: BsCss.overflow("hidden"),
                    tl: {
                      hd: BsCss.borderTopRightRadius(theme.borderRadius),
                      tl: {
                        hd: BsCss.minHeight(BsCss.px(200)),
                        tl: {
                          hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.CategoryTiles.tabletOrDesktop, {
                                hd: BsCss.height(BsCss.pct(100)),
                                tl: {
                                  hd: BsCss.borderBottomRightRadius(theme.borderRadius),
                                  tl: /* [] */0
                                }
                              }),
                          tl: {
                            hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.CategoryTiles.mobile, {
                                  hd: BsCss.width(BsCss.pct(100)),
                                  tl: {
                                    hd: BsCss.borderTopLeftRadius(theme.borderRadius),
                                    tl: /* [] */0
                                  }
                                }),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var videoPlayer = Curry._1(BsCss.style, {
      hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.CategoryTiles.mobile, {
            hd: BsCss.important(BsCss.width(BsCss.pct(100))),
            tl: /* [] */0
          }),
      tl: /* [] */0
    });

var ctaVideoPlayer = Curry._1(BsCss.style, {
      hd: BsCss.borderRadius(BsCss.px(10)),
      tl: {
        hd: BsCss.important(BsCss.width(BsCss.pct(100))),
        tl: {
          hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.CategoryTiles.mobile, {
                hd: BsCss.important(BsCss.width(BsCss.pct(100))),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var ctaBannerImageStyles = Curry._1(BsCss.style, {
      hd: BsCss.visibility("hidden"),
      tl: /* [] */0
    });

var container = Curry._1(BsCss.style, {
      hd: BsCss.display("flex"),
      tl: {
        hd: BsCss.height(BsCss.pct(100)),
        tl: {
          hd: BsCss.maxWidth(BsCss.pct(100)),
          tl: {
            hd: BsCss.flex3(0, 0, BsCss.pct(100)),
            tl: {
              hd: BsCss.children({
                    hd: BsCss.maxWidth(BsCss.pct(100)),
                    tl: {
                      hd: BsCss.flex3(0, 0, BsCss.pct(100)),
                      tl: /* [] */0
                    }
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var Styles = {
  ctaImageContainer: ctaImageContainer,
  ctaImageAction: ctaImageAction,
  ctaVideoContainer: ctaVideoContainer,
  ctaVideoAction: ctaVideoAction,
  noImageContentContainer: noImageContentContainer,
  noImageContentHead: noImageContentHead,
  noImageContentAction: noImageContentAction,
  videoBannerLink: videoBannerLink,
  backgroundImageStyle: backgroundImageStyle,
  transparentBannerImage: transparentBannerImage,
  backgroundStyle: backgroundStyle,
  mobileBackgroundStyle: mobileBackgroundStyle,
  bgColorStyle: bgColorStyle,
  foregroundDark: foregroundDark,
  foregroundLight: foregroundLight,
  videoContainer: videoContainer,
  videoPlayer: videoPlayer,
  ctaVideoPlayer: ctaVideoPlayer,
  ctaBannerImageStyles: ctaBannerImageStyles,
  container: container
};

function HeroTileSection$LargeTile(props) {
  var tile = props.tile;
  var backgroundImageUrl = Belt_Option.mapWithDefault(tile.background_image, "", (function (b) {
          return b.url;
        }));
  var backgroundImageUrlMobile = Belt_Option.mapWithDefault(tile.mobile_background_image, "", (function (b) {
          return b.url;
        }));
  var backgroundImageUrlTablet = Belt_Option.mapWithDefault(tile.tablet_background_image, "", (function (b) {
          return b.url;
        }));
  var videoUrl = Belt_Option.mapWithDefault(tile.background_video, "", (function (v) {
          return v;
        }));
  var mobileVideoUrl = Belt_Option.mapWithDefault(tile.mobile_background_video, "", (function (v) {
          return v;
        }));
  var tabletVideoUrl = Belt_Option.mapWithDefault(tile.tablet_background_video, "", (function (v) {
          return v;
        }));
  var findBackgroundImage = function (width) {
    if (width < Theme__Responsive$PrezzeeRibbon.largeScreenBreakpoint && width > Theme__Responsive$PrezzeeRibbon.CategoryTiles.mobileBreakpoint) {
      return tile.tablet_background_image;
    } else if (width <= Theme__Responsive$PrezzeeRibbon.CategoryTiles.mobileBreakpoint) {
      return tile.mobile_background_image;
    } else {
      return tile.background_image;
    }
  };
  var findVideoUrl = function (width) {
    if (width < Theme__Responsive$PrezzeeRibbon.largeScreenBreakpoint && width > Theme__Responsive$PrezzeeRibbon.CategoryTiles.mobileBreakpoint) {
      return tabletVideoUrl;
    } else if (width <= Theme__Responsive$PrezzeeRibbon.CategoryTiles.mobileBreakpoint) {
      return mobileVideoUrl;
    } else {
      return videoUrl;
    }
  };
  var match = React.useState(function () {
        return setTimeout((function (param) {
                      
                    }), 0);
      });
  var setIframeTimeout = match[1];
  var iframeTimeout = match[0];
  var initVimeo = function (param) {
    clearTimeout(iframeTimeout);
    Curry._1(setIframeTimeout, (function (param) {
            return setTimeout((function (param) {
                          var playerDiv = document.getElementById("hero-tile-player");
                          if (playerDiv == null) {
                            return ;
                          }
                          var iframe = playerDiv.querySelector("iframe");
                          if (!(iframe == null)) {
                            iframe.setAttribute("style", Js_string.concat(";border-radius: 10px;", iframe.getAttribute("style")));
                            return ;
                          }
                          
                        }), 1000);
          }));
  };
  var match$1 = React.useState(function () {
        
      });
  var setTileImage = match$1[1];
  var tileImage = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setTileVideoUrl = match$2[1];
  var tileVideoUrl = match$2[0];
  React.useEffect((function () {
          var width = window.innerWidth;
          Curry._1(setTileImage, (function (param) {
                  return findBackgroundImage(width);
                }));
          Curry._1(setTileVideoUrl, (function (param) {
                  return findVideoUrl(width);
                }));
          window.addEventListener("resize", (function (param) {
                  var width = window.innerWidth;
                  Curry._1(setTileImage, (function (param) {
                          return findBackgroundImage(width);
                        }));
                  Curry._1(setTileVideoUrl, (function (param) {
                          return findVideoUrl(width);
                        }));
                  initVimeo(undefined);
                }));
        }), []);
  var validBackgroundImageUrl = backgroundImageUrl !== "";
  var match$3 = React.useReducer((function (x, param) {
          return x + 1 | 0;
        }), 0);
  var forceUpdate = match$3[1];
  var backgroundStyle$1 = Belt_Option.mapWithDefault(tile.background_image, "", (function (b) {
          return backgroundStyle(b.url + "?fm=png&auto=format");
        }));
  var mobileBackgroundStyle$1 = Belt_Option.mapWithDefault(tile.mobile_background_image, "", (function (b) {
          return mobileBackgroundStyle(b.url + "?fm=png&auto=format");
        }));
  var backgroundImageClassName = Cn.make([
        backgroundStyle$1,
        mobileBackgroundStyle$1
      ]);
  var buttons = Belt_Array.mapWithIndex(tile.buttons, (function (i, b) {
          var target = b.open_in_new_window ? "_blank" : "";
          var match = b.smartLink;
          return JsxRuntime.jsx(Button$PrezzeeRibbon.make, {
                      children: JsxRuntime.jsx(Link, {
                            href: b.cta_url,
                            prefetch: false,
                            children: JsxRuntime.jsx("a", {
                                  children: JsxRuntime.jsx(KontentContextProvider.make, {
                                        children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                                              children: JsxRuntime.jsx("button", {
                                                    children: b.cta_text
                                                  })
                                            }),
                                        itemId: match.itemId,
                                        elementCodename: match.codename
                                      }),
                                  target: target
                                })
                          }),
                      fill: b.fill,
                      colour: b.colour,
                      variant: b.variant
                    }, String(i));
        }));
  var match$4 = tile.text_colour;
  var foregroundStyle = match$4 ? foregroundLight : foregroundDark;
  var color = tile.background_colour;
  var backgroundColorStyle = color !== undefined ? bgColorStyle(color) : "";
  var validDesktopUrl = Regex.vimeoRegex.test(videoUrl);
  var validMobileUrl = Regex.vimeoRegex.test(mobileVideoUrl);
  var invalidMsg = "is not a valid vimeo url.";
  if (!validDesktopUrl && Belt_Option.isSome(tile.background_video)) {
    Pino.logger.info({
          type: "Hero Tile Section Desktop Video Url"
        }, "info: \"" + videoUrl + "\" " + invalidMsg + "");
  }
  if (!validMobileUrl && Belt_Option.isSome(tile.mobile_background_video)) {
    Pino.logger.info({
          type: "Hero Tile Section Mobile Video Url"
        }, "info: \"" + mobileVideoUrl + "\" " + invalidMsg + "");
  }
  var playerOptions = Vimeo$PrezzeeRibbon.Player.options(undefined, true, undefined, undefined, undefined, false, undefined, tile.loop_background_video, true, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined);
  var match$5 = tile.content_vertical_alignment;
  var contentVerticalAlignment = match$5 === "bottom" ? /* Bottom */1 : /* Default */0;
  var match$6 = tile.background_horizontal_alignment;
  var backgroundHorizontalAlignment = match$6 === "left" ? /* Left */0 : /* Right */1;
  var content = JsxRuntime.jsxs(LargeHeroTile__Foreground$PrezzeeRibbon.make, {
        children: [
          JsxRuntime.jsx(LargeHeroTile__Heading$PrezzeeRibbon.make, {
                children: JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
                      children: tile.title
                    })
              }),
          JsxRuntime.jsx(LargeHeroTile__BodyText$PrezzeeRibbon.make, {
                children: JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
                      children: tile.description
                    })
              }),
          JsxRuntime.jsx(LargeHeroTile__CtaLine$PrezzeeRibbon.make, {
                children: buttons
              })
        ],
        className: foregroundStyle,
        vAlign: contentVerticalAlignment
      });
  var ctaContent = null;
  var noImageContent = JsxRuntime.jsxs("div", {
        children: [
          JsxRuntime.jsxs("div", {
                children: [
                  JsxRuntime.jsx(LargeHeroTile__Heading$PrezzeeRibbon.make, {
                        children: JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
                              children: tile.title
                            })
                      }),
                  JsxRuntime.jsx(LargeHeroTile__BodyText$PrezzeeRibbon.make, {
                        children: JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
                              children: tile.description
                            })
                      })
                ],
                className: noImageContentHead(tile.text_colour)
              }),
          JsxRuntime.jsx("div", {
                children: JsxRuntime.jsx(LargeHeroTile__CtaLine$PrezzeeRibbon.make, {
                      children: buttons
                    }),
                className: noImageContentAction
              })
        ],
        className: noImageContentContainer
      });
  var player = JsxRuntime.jsx(VimeoPlayer__Player$PrezzeeRibbon.make, {
        className: videoPlayer,
        id: "hero-tile-player",
        playerOptions: playerOptions,
        maintainAspectByHeight: true
      });
  var ctaPlayer = JsxRuntime.jsx(VimeoPlayer__Player$PrezzeeRibbon.make, {
        className: ctaVideoPlayer,
        id: "hero-tile-player",
        playerOptions: playerOptions,
        maintainAspectByHeight: true
      });
  var theme = ThemeContext$PrezzeeRibbon.useTheme(undefined);
  var video = JsxRuntime.jsx("div", {
        children: JsxRuntime.jsx(VimeoPlayer__Video$PrezzeeRibbon.make, {
              videoUrl: videoUrl,
              children: player
            }),
        className: videoContainer(theme)
      });
  var mobileVideo = JsxRuntime.jsx("div", {
        children: JsxRuntime.jsx(VimeoPlayer__MobileAwareVideo$PrezzeeRibbon.make, {
              videoUrl: videoUrl,
              mobileUrl: mobileVideoUrl,
              children: player,
              mobileBreakpoint: Theme__Responsive$PrezzeeRibbon.CategoryTiles.mobileBreakpoint
            }),
        className: videoContainer(theme)
      });
  React.useEffect((function () {
          Curry._1(forceUpdate, undefined);
          initVimeo(undefined);
        }), []);
  var match$7 = tile.smartLink;
  var ctaLink = Belt_Array.get(Belt_Array.map(tile.buttons, (function (b) {
              return b.cta_url;
            })), 0);
  var ctaBannerImage = JsxRuntime.jsx(Link, {
        href: String(ctaLink),
        prefetch: false,
        children: JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(LargeHeroTile__Background$PrezzeeRibbon.make, {
                    children: JsxRuntime.jsxs("div", {
                          children: [
                            validBackgroundImageUrl && tileImage !== undefined ? JsxRuntime.jsx(CmsImage.make, {
                                    src: tileImage.url,
                                    alt: tileImage.description,
                                    width: tileImage.width,
                                    height: tileImage.height,
                                    className: transparentBannerImage,
                                    sizes: "(min-width: 1440px) 1380px, 100vw",
                                    priority: true
                                  }) : null,
                            JsxRuntime.jsx("div", {
                                  children: validBackgroundImageUrl ? ctaContent : noImageContent,
                                  className: ctaImageAction(tile.infoCta)
                                })
                          ],
                          className: ctaImageContainer(backgroundImageUrl + "?fm=png&auto=format", backgroundImageUrlTablet + "?fm=png&auto=format", backgroundImageUrlMobile + "?fm=png&auto=format", String(tile.background_colour))
                        }),
                    backgroundImageClassName: backgroundImageStyle,
                    backgroundColorClassName: backgroundColorStyle,
                    alignBackground: backgroundHorizontalAlignment
                  })
            })
      });
  var ctaBannerVideo = JsxRuntime.jsxs("div", {
        children: [
          JsxRuntime.jsx(Link, {
                href: String(ctaLink),
                prefetch: false,
                children: JsxRuntime.jsx("div", {
                      className: videoBannerLink
                    })
              }),
          validDesktopUrl && validMobileUrl ? JsxRuntime.jsx(VimeoPlayer__MobileAwareVideo$PrezzeeRibbon.make, {
                  videoUrl: tileVideoUrl,
                  mobileUrl: mobileVideoUrl,
                  children: ctaPlayer,
                  mobileBreakpoint: Theme__Responsive$PrezzeeRibbon.CategoryTiles.mobileBreakpoint
                }) : (
              validDesktopUrl ? JsxRuntime.jsx(VimeoPlayer__Video$PrezzeeRibbon.make, {
                      videoUrl: tileVideoUrl,
                      children: ctaPlayer
                    }) : null
            ),
          JsxRuntime.jsx("div", {
                children: ctaContent,
                className: ctaVideoAction
              })
        ],
        className: ctaVideoContainer
      });
  var banner = JsxRuntime.jsxs(LargeHeroTile__Background$PrezzeeRibbon.make, {
        children: [
          validDesktopUrl && validMobileUrl ? mobileVideo : (
              validDesktopUrl ? video : null
            ),
          content
        ],
        backgroundImageClassName: backgroundImageClassName,
        backgroundColorClassName: backgroundColorStyle,
        alignBackground: backgroundHorizontalAlignment
      });
  return JsxRuntime.jsx(KontentContextProvider.make, {
              children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                    children: JsxRuntime.jsx("div", {
                          children: JsxRuntime.jsx(LargeHeroTile$PrezzeeRibbon.make, {
                                children: tile.cta ? (
                                    validDesktopUrl && validMobileUrl ? ctaBannerVideo : ctaBannerImage
                                  ) : banner
                              }, match$3[0].toString()),
                          className: container
                        })
                  }),
              itemId: match$7.itemId,
              elementCodename: match$7.codename
            });
}

var LargeTile = {
  Styles: Styles,
  make: HeroTileSection$LargeTile
};

var videoContainer$1 = Curry._1(BsCss.style, {
      hd: BsCss.height(BsCss.zero),
      tl: {
        hd: BsCss.paddingBottom(BsCss.pct(56.25)),
        tl: {
          hd: BsCss.children({
                hd: BsCss.position("absolute"),
                tl: {
                  hd: BsCss.top(BsCss.pct(50)),
                  tl: {
                    hd: BsCss.left(BsCss.zero),
                    tl: {
                      hd: BsCss.transform({
                            NAME: "translateY",
                            VAL: BsCss.pct(-50)
                          }),
                      tl: {
                        hd: BsCss.width(BsCss.pct(100)),
                        tl: {
                          hd: BsCss.maxHeight(BsCss.vh(90)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }),
          tl: /* [] */0
        }
      }
    });

function playButtonContainer(foregroundColor) {
  return Curry._1(BsCss.style, {
              hd: BsCss.display("flex"),
              tl: {
                hd: BsCss.alignItems("center"),
                tl: {
                  hd: BsCss.color(foregroundColor ? Theme__Color$PrezzeeRibbon.white : Theme__Color$PrezzeeRibbon.gray600),
                  tl: /* [] */0
                }
              }
            });
}

var playButton = Curry._1(BsCss.style, {
      hd: BsCss.marginRight(BsCss.px(10)),
      tl: {
        hd: BsCss.selector("button", {
              hd: BsCss.important(BsCss.borderWidth(BsCss.px(1))),
              tl: {
                hd: BsCss.important(BsCss.fontSize(BsCss.px(14))),
                tl: /* [] */0
              }
            }),
        tl: {
          hd: BsCss.children({
                hd: BsCss.nthChild({
                      NAME: "add",
                      VAL: [
                        1,
                        2
                      ]
                    }, {
                      hd: BsCss.important(BsCss.marginLeft(BsCss.px(10))),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var Styles$1 = {
  videoContainer: videoContainer$1,
  playButtonContainer: playButtonContainer,
  playButton: playButton
};

function HeroTileSection$VideoModal(props) {
  var foregroundColor = props.foregroundColor;
  var match = React.useState(function () {
        return false;
      });
  var setModalOpen = match[1];
  var onRequestClose = function (param) {
    Curry._1(setModalOpen, (function (param) {
            return false;
          }));
  };
  var openFunc = function (param) {
    Curry._1(setModalOpen, (function (param) {
            return true;
          }));
  };
  var playerOptions = Vimeo$PrezzeeRibbon.Player.options(undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined);
  var content = JsxRuntime.jsx("div", {
        children: JsxRuntime.jsx(VimeoPlayer__Video$PrezzeeRibbon.make, {
              videoUrl: props.videoUrl,
              children: JsxRuntime.jsx(VimeoPlayer__Player$PrezzeeRibbon.make, {
                    id: "video-modal-story",
                    playerOptions: playerOptions
                  })
            }),
        className: videoContainer$1
      });
  var btnColor = foregroundColor ? /* White */2 : /* Tertiary */7;
  return JsxRuntime.jsxs(Modal$PrezzeeRibbon.make, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Button$PrezzeeRibbon.make, {
                              children: JsxRuntime.jsx("button", {
                                    children: JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                                          icon: FaPlay.faPlay
                                        }),
                                    onClick: openFunc
                                  }),
                              fill: /* Outlined */1,
                              colour: btnColor,
                              variant: /* Icon */2,
                              className: playButton
                            }),
                        JsxRuntime.jsx(Fresnel$PrezzeeRibbon.Media.make, {
                              greaterThan: "rbMobile",
                              children: JsxRuntime.jsx("span", {
                                    children: props.ctaText,
                                    className: Theme__Typography$PrezzeeRibbon.Styles.style6
                                  })
                            })
                      ],
                      className: playButtonContainer(foregroundColor)
                    }),
                JsxRuntime.jsxs(Modal__Container$PrezzeeRibbon.make, {
                      id: "small-hero-modal-container",
                      isOpen: match[0],
                      onRequestClose: onRequestClose,
                      children: [
                        JsxRuntime.jsx(Modal__Close$PrezzeeRibbon.make, {
                              closeFunc: onRequestClose
                            }),
                        content
                      ],
                      borderless: true
                    })
              ]
            });
}

var VideoModal = {
  Styles: Styles$1,
  make: HeroTileSection$VideoModal
};

function backgroundStyle$1(url) {
  return Curry._1(BsCss.style, {
              hd: BsCss.backgroundImage({
                    NAME: "url",
                    VAL: url
                  }),
              tl: /* [] */0
            });
}

var foregroundDark$1 = Curry._1(BsCss.style, {
      hd: BsCss.color("currentColor"),
      tl: /* [] */0
    });

var foregroundLight$1 = Curry._1(BsCss.style, {
      hd: BsCss.selector("h1, h2, h3, h4, h5, h6, p", {
            hd: BsCss.important(BsCss.color(Theme.Color.white)),
            tl: /* [] */0
          }),
      tl: /* [] */0
    });

function foregroundImage(w, h) {
  return Curry._1(BsCss.style, {
              hd: BsCss.width(BsCss.px(w)),
              tl: {
                hd: BsCss.height(BsCss.px(h)),
                tl: /* [] */0
              }
            });
}

var container$1 = Curry._1(BsCss.style, {
      hd: BsCss.display("flex"),
      tl: {
        hd: BsCss.height(BsCss.pct(100)),
        tl: {
          hd: BsCss.maxWidth(BsCss.pct(100)),
          tl: {
            hd: BsCss.flex3(0, 0, BsCss.pct(100)),
            tl: {
              hd: BsCss.children({
                    hd: BsCss.maxWidth(BsCss.pct(100)),
                    tl: {
                      hd: BsCss.flex3(0, 0, BsCss.pct(100)),
                      tl: /* [] */0
                    }
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var Styles$2 = {
  backgroundStyle: backgroundStyle$1,
  foregroundDark: foregroundDark$1,
  foregroundLight: foregroundLight$1,
  foregroundImage: foregroundImage,
  container: container$1
};

function HeroTileSection$SmallTile(props) {
  var tile = props.tile;
  var match = React.useReducer((function (x, param) {
          return x + 1 | 0;
        }), 0);
  var smartLink = tile.smartLink;
  var foreground_image = tile.foreground_image;
  var text_colour = tile.text_colour;
  var forceUpdate = match[1];
  var backgroundImageClassName = Belt_Option.mapWithDefault(tile.background_image, "", (function (b) {
          return backgroundStyle$1(b.url + "?fm=png&auto=format");
        }));
  var foregroundStyle = text_colour ? foregroundLight$1 : foregroundDark$1;
  var image;
  if (foreground_image !== undefined) {
    var height = foreground_image.height;
    var width = foreground_image.width;
    image = JsxRuntime.jsx(SmallHeroTile__CardImage$PrezzeeRibbon.make, {
          children: JsxRuntime.jsx(CmsImage.make, {
                src: foreground_image.url,
                alt: foreground_image.description,
                width: width,
                height: height,
                className: foregroundImage(width, height),
                sizes: "285px",
                priority: true
              })
        });
  } else {
    image = null;
  }
  var videoUrl = Belt_Option.mapWithDefault(tile.background_video, "", (function (v) {
          return v;
        }));
  var validVideoUrl = Regex.vimeoRegex.test(videoUrl);
  React.useEffect((function () {
          Curry._1(forceUpdate, undefined);
        }), []);
  var btns = Belt_Array.mapWithIndex(tile.buttons, (function (i, b) {
          var target = b.open_in_new_window ? "_blank" : "";
          var match = b.smartLink;
          return JsxRuntime.jsx(Button$PrezzeeRibbon.make, {
                      children: JsxRuntime.jsx(Link, {
                            href: b.cta_url,
                            prefetch: false,
                            children: JsxRuntime.jsx("a", {
                                  children: JsxRuntime.jsx(KontentContextProvider.make, {
                                        children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                                              children: JsxRuntime.jsx("button", {
                                                    children: b.cta_text
                                                  })
                                            }),
                                        itemId: match.itemId,
                                        elementCodename: match.codename
                                      }),
                                  target: target
                                })
                          }),
                      fill: b.fill,
                      colour: b.colour,
                      variant: b.variant
                    }, String(i));
        }));
  return JsxRuntime.jsx(KontentContextProvider.make, {
              children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                    children: JsxRuntime.jsx("div", {
                          children: JsxRuntime.jsx(SmallHeroTile$PrezzeeRibbon.make, {
                                children: JsxRuntime.jsx(SmallHeroTile__Background$PrezzeeRibbon.make, {
                                      children: JsxRuntime.jsxs(SmallHeroTile__Foreground$PrezzeeRibbon.make, {
                                            children: [
                                              JsxRuntime.jsx(SmallHeroTile__Heading$PrezzeeRibbon.make, {
                                                    children: JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
                                                          children: tile.title
                                                        })
                                                  }),
                                              JsxRuntime.jsx(SmallHeroTile__BodyText$PrezzeeRibbon.make, {
                                                    children: JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
                                                          children: tile.description
                                                        })
                                                  }),
                                              JsxRuntime.jsxs(SmallHeroTile__CtaLine$PrezzeeRibbon.make, {
                                                    children: [
                                                      btns,
                                                      validVideoUrl ? JsxRuntime.jsx(HeroTileSection$VideoModal, {
                                                              videoUrl: videoUrl,
                                                              ctaText: tile.video_cta_text,
                                                              foregroundColor: text_colour
                                                            }) : null
                                                    ]
                                                  })
                                            ],
                                            image: image,
                                            className: foregroundStyle
                                          }),
                                      backgroundImageClassName: backgroundImageClassName
                                    })
                              }, match[0].toString()),
                          className: container$1
                        })
                  }),
              itemId: smartLink.itemId,
              elementCodename: smartLink.codename
            });
}

var SmallTile = {
  Styles: Styles$2,
  make: HeroTileSection$SmallTile
};

function HeroTileSection$TwoTiles(props) {
  var leftTile = JsxRuntime.jsx(HeroTileSection$LargeTile, {
        tile: props.tile1
      });
  var rightTile = JsxRuntime.jsx(HeroTileSection$SmallTile, {
        tile: props.tile2
      });
  return JsxRuntime.jsx(TwoOneHeroTiles$PrezzeeRibbon.make, {
              leftTile: leftTile,
              rightTile: rightTile
            });
}

var TwoTiles = {
  make: HeroTileSection$TwoTiles
};

function HeroTileSection$FullWidthTile(props) {
  return JsxRuntime.jsx(HeroTileSection$LargeTile, {
              tile: props.tile
            });
}

var FullWidthTile = {
  make: HeroTileSection$FullWidthTile
};

function section(top_distance, bottom_distance) {
  return Curry._1(BsCss.style, {
              hd: BsCss.marginTop(Belt_Option.isNone(top_distance) ? BsCss.rem(0.75) : BsCss.px(Belt_Option.getWithDefault(top_distance, 0))),
              tl: {
                hd: BsCss.marginBottom(Belt_Option.isNone(bottom_distance) ? BsCss.rem(2.5) : BsCss.px(Belt_Option.getWithDefault(bottom_distance, 0))),
                tl: /* [] */0
              }
            });
}

var Styles$3 = {
  section: section
};

function HeroTileSection(props) {
  var block = props.block;
  var match = block.smartLink;
  var match$1 = block.block_style;
  var tiles;
  switch (match$1) {
    case /* TwoOneStack */0 :
        var match$2 = block.hero_tiles;
        if (match$2.length !== 2) {
          tiles = null;
        } else {
          var tile1 = match$2[0];
          var tile2 = match$2[1];
          tiles = JsxRuntime.jsx(HeroTileSection$TwoTiles, {
                tile1: tile1,
                tile2: tile2
              });
        }
        break;
    case /* TiledOffset */1 :
        tiles = null;
        break;
    case /* FullWidth */2 :
        var match$3 = block.hero_tiles;
        if (match$3.length !== 1) {
          tiles = null;
        } else {
          var tile = match$3[0];
          tiles = JsxRuntime.jsx(HeroTileSection$FullWidthTile, {
                tile: tile
              });
        }
        break;
    
  }
  return JsxRuntime.jsx(KontentContextProvider.make, {
              children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                    children: JsxRuntime.jsx("section", {
                          children: tiles,
                          className: Cn.make([
                                IndexStyles.fullWidthSection,
                                section(block.top_distance, block.bottom_distance)
                              ])
                        })
                  }),
              itemId: match.itemId,
              elementCodename: match.codename
            });
}

var KontentContextConsumer;

var make = HeroTileSection;

export {
  KontentContextConsumer ,
  getBgImage ,
  LargeTile ,
  VideoModal ,
  SmallTile ,
  TwoTiles ,
  FullWidthTile ,
  Styles$3 as Styles,
  make ,
}
/* ctaVideoContainer Not a pure module */
