// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as BsCss from "../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as CmsImage from "../CmsImage.bs.js";
import Link from "next/link";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as IconCard$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/IconCard/IconCard.bs.js";
import * as KontentContextProvider from "../pages/index/KontentContextProvider.bs.js";
import * as RichText$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RichText/RichText.bs.js";
import * as SimpleIcon$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/SimpleIcon/SimpleIcon.bs.js";
import * as IconCard__Heading$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/IconCard/IconCard__Heading.bs.js";
import * as Theme__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Responsive.bs.js";
import * as IconCard__BodyText$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/IconCard/IconCard__BodyText.bs.js";

var iconLink = Curry._1(BsCss.style, {
      hd: BsCss.display("flex"),
      tl: {
        hd: BsCss.textDecoration("none"),
        tl: {
          hd: BsCss.children({
                hd: BsCss.width(BsCss.pct(100)),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var mediumIcon = Curry._1(BsCss.style, {
      hd: BsCss.width(BsCss.rem(2.5)),
      tl: {
        hd: BsCss.height(BsCss.rem(2.5)),
        tl: {
          hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.largeScreen, {
                hd: BsCss.width(BsCss.rem(3.5)),
                tl: {
                  hd: BsCss.height(BsCss.rem(3.5)),
                  tl: /* [] */0
                }
              }),
          tl: /* [] */0
        }
      }
    });

var largeIcon = Curry._1(BsCss.style, {
      hd: BsCss.width("auto"),
      tl: {
        hd: BsCss.height(BsCss.rem(5.0)),
        tl: /* [] */0
      }
    });

function contentWrapper(textAlignment, flexAligment) {
  return Curry._1(BsCss.style, {
              hd: BsCss.display("flex"),
              tl: {
                hd: BsCss.flexDirection("column"),
                tl: {
                  hd: BsCss.alignItems(flexAligment),
                  tl: {
                    hd: BsCss.textAlign(textAlignment),
                    tl: /* [] */0
                  }
                }
              }
            });
}

var Styles = {
  iconLink: iconLink,
  mediumIcon: mediumIcon,
  largeIcon: largeIcon,
  contentWrapper: contentWrapper
};

function CardBuilder(props) {
  var themeType = props.themeType;
  return Belt_Array.mapWithIndex(props.iconCards, (function (idx, card) {
                var smartLink = card.smartLink;
                var contentAlignment = card.contentAlignment;
                var icon = card.icon;
                var background_colour = card.background_colour;
                var text_colour = card.text_colour;
                var url = card.url;
                var key = String(idx);
                var displayIcon;
                if (icon !== undefined) {
                  var height = icon.height;
                  var width = icon.width;
                  var url$1 = icon.url;
                  var description = icon.description;
                  switch (card.iconSize) {
                    case "large" :
                        displayIcon = JsxRuntime.jsx(CmsImage.make, {
                              src: url$1,
                              alt: description,
                              width: width,
                              height: height,
                              className: largeIcon
                            });
                        break;
                    case "medium" :
                        displayIcon = JsxRuntime.jsx(CmsImage.make, {
                              src: url$1,
                              alt: description,
                              width: width,
                              height: height,
                              className: mediumIcon
                            });
                        break;
                    default:
                      displayIcon = JsxRuntime.jsx(SimpleIcon$PrezzeeRibbon.make, {
                            children: JsxRuntime.jsx(CmsImage.make, {
                                  src: url$1,
                                  alt: description,
                                  width: width,
                                  height: height
                                })
                          });
                  }
                } else {
                  displayIcon = null;
                }
                var textStyle = text_colour === "" ? "" : Curry._1(BsCss.style, {
                        hd: BsCss.selector("p", {
                              hd: BsCss.unsafe("color", text_colour + "!important"),
                              tl: /* [] */0
                            }),
                        tl: {
                          hd: BsCss.selector("h1, h2, h3, h4, h5, h6", {
                                hd: BsCss.unsafe("color", card.title_colour + "!important"),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      });
                var bgStyle = background_colour === "" ? "" : Curry._1(Css.style, {
                        hd: Css.unsafe("backgroundColor", background_colour + "!important"),
                        tl: /* [] */0
                      });
                var cardStyle = Cn.make([
                      textStyle,
                      bgStyle
                    ]);
                var aligmentStyle;
                switch (contentAlignment) {
                  case "center" :
                      aligmentStyle = "center";
                      break;
                  case "left" :
                      aligmentStyle = "left";
                      break;
                  default:
                    aligmentStyle = "left";
                }
                var flexAligment;
                switch (contentAlignment) {
                  case "center" :
                      flexAligment = "center";
                      break;
                  case "left" :
                      flexAligment = "flexStart";
                      break;
                  default:
                    flexAligment = "flexStart";
                }
                var iconCardElement = JsxRuntime.jsx(IconCard$PrezzeeRibbon.make, {
                      children: JsxRuntime.jsx(KontentContextProvider.make, {
                            children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                                  children: JsxRuntime.jsxs("div", {
                                        children: [
                                          displayIcon,
                                          JsxRuntime.jsx(IconCard__Heading$PrezzeeRibbon.make, {
                                                children: card.title,
                                                themeType: themeType
                                              }),
                                          JsxRuntime.jsx(IconCard__BodyText$PrezzeeRibbon.make, {
                                                children: JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
                                                      children: card.description
                                                    }),
                                                themeType: themeType
                                              })
                                        ],
                                        className: contentWrapper(aligmentStyle, flexAligment)
                                      })
                                }),
                            itemId: smartLink.itemId,
                            elementCodename: smartLink.codename
                          }),
                      themeType: themeType,
                      className: cardStyle
                    }, key);
                if (url === "") {
                  return JsxRuntime.jsx("div", {
                              children: iconCardElement,
                              className: iconLink
                            }, key);
                } else if (card.open_in_new_window) {
                  return JsxRuntime.jsx("a", {
                              children: iconCardElement,
                              className: iconLink,
                              href: url,
                              target: "_blank"
                            }, key);
                } else {
                  return JsxRuntime.jsx(Link, {
                              href: url,
                              prefetch: false,
                              children: JsxRuntime.jsx("a", {
                                    children: iconCardElement,
                                    className: iconLink,
                                    target: "_self"
                                  })
                            }, key);
                }
              }));
}

var KontentContextConsumer;

var RichText;

var Link$1;

var IconCard;

var Heading;

var BodyText;

var SimpleIcon;

var make = CardBuilder;

export {
  Styles ,
  KontentContextConsumer ,
  RichText ,
  Link$1 as Link,
  IconCard ,
  Heading ,
  BodyText ,
  SimpleIcon ,
  make ,
}
/* iconLink Not a pure module */
