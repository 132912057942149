// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as IndexStyles from "./IndexStyles.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as KontentContextProvider from "./KontentContextProvider.bs.js";
import * as RichText$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RichText/RichText.bs.js";
import * as StatsTile$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/StatsTile/StatsTile.bs.js";
import * as Theme__Color$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Color.bs.js";
import * as StatsTileBlock$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/StatsTile/StatsTileBlock.bs.js";
import * as Theme__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Responsive.bs.js";

var section = Curry._1(BsCss.merge, {
      hd: IndexStyles.section,
      tl: {
        hd: Curry._1(BsCss.style, {
              hd: BsCss.maxWidth(BsCss.px(930)),
              tl: {
                hd: BsCss.marginTop(BsCss.rem(5)),
                tl: {
                  hd: BsCss.marginBottom(BsCss.rem(5)),
                  tl: {
                    hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.desktop, {
                          hd: BsCss.marginTop(BsCss.rem(8.4375)),
                          tl: {
                            hd: BsCss.marginBottom(BsCss.rem(8.4375)),
                            tl: /* [] */0
                          }
                        }),
                    tl: {
                      hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.tablet, {
                            hd: BsCss.marginTop(BsCss.rem(6.25)),
                            tl: {
                              hd: BsCss.marginBottom(BsCss.rem(6.25)),
                              tl: /* [] */0
                            }
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var contentContainer = Curry._1(BsCss.style, {
      hd: BsCss.display("flex"),
      tl: {
        hd: BsCss.flexDirection("column"),
        tl: {
          hd: BsCss.justifyContent("center"),
          tl: {
            hd: BsCss.alignItems("center"),
            tl: /* [] */0
          }
        }
      }
    });

var heading = Curry._1(BsCss.style, {
      hd: BsCss.fontWeight("semiBold"),
      tl: {
        hd: BsCss.textAlign("center"),
        tl: {
          hd: BsCss.color(Theme__Color$PrezzeeRibbon.Personal.charcoal),
          tl: {
            hd: BsCss.fontSize(BsCss.px(28)),
            tl: {
              hd: BsCss.lineHeight(BsCss.px(36)),
              tl: {
                hd: BsCss.marginBottom(BsCss.px(10)),
                tl: {
                  hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.desktop, {
                        hd: BsCss.fontSize(BsCss.px(40)),
                        tl: {
                          hd: BsCss.lineHeight(BsCss.px(50)),
                          tl: {
                            hd: BsCss.marginBottom(BsCss.px(12)),
                            tl: /* [] */0
                          }
                        }
                      }),
                  tl: {
                    hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.tablet, {
                          hd: BsCss.fontSize(BsCss.px(36)),
                          tl: {
                            hd: BsCss.lineHeight(BsCss.px(46)),
                            tl: {
                              hd: BsCss.marginBottom(BsCss.px(15)),
                              tl: /* [] */0
                            }
                          }
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var description = Curry._1(BsCss.style, {
      hd: BsCss.textAlign("center"),
      tl: {
        hd: BsCss.marginBottom(BsCss.px(29)),
        tl: {
          hd: BsCss.color(Theme__Color$PrezzeeRibbon.Personal.midGrey),
          tl: {
            hd: BsCss.fontSize(BsCss.px(16)),
            tl: {
              hd: BsCss.lineHeight(BsCss.px(24)),
              tl: {
                hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.desktop, {
                      hd: BsCss.fontSize(BsCss.px(18)),
                      tl: {
                        hd: BsCss.lineHeight(BsCss.px(26)),
                        tl: /* [] */0
                      }
                    }),
                tl: {
                  hd: BsCss.selector("&:last-child", {
                        hd: BsCss.marginBottom("zero"),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var iconImage = Curry._1(BsCss.style, {
      hd: BsCss.maxWidth(BsCss.pct(100)),
      tl: {
        hd: BsCss.width("auto"),
        tl: {
          hd: BsCss.height(BsCss.px(33)),
          tl: {
            hd: BsCss.marginRight("auto"),
            tl: /* [] */0
          }
        }
      }
    });

function createFluidTypography(minSize, maxSize) {
  var min = String(minSize);
  var max = String(maxSize);
  var calc = "calc(" + min + "px + (" + max + " - " + min + ") * ((100vw - 300px) / (1600 - 300)))";
  var clamp = "clamp(" + min + "px, " + calc + ", " + max + "px)";
  return BsCss.unsafe("fontSize", clamp);
}

var titleBase = Curry._1(BsCss.style, {
      hd: BsCss.margin("zero"),
      tl: {
        hd: BsCss.fontWeight("semiBold"),
        tl: /* [] */0
      }
    });

var subtitleBase = Curry._1(BsCss.style, {
      hd: BsCss.opacity(0.7),
      tl: {
        hd: BsCss.fontWeight("medium"),
        tl: /* [] */0
      }
    });

var firstTile_title = Curry._1(BsCss.merge, {
      hd: titleBase,
      tl: {
        hd: Curry._1(BsCss.style, {
              hd: createFluidTypography(32, 38),
              tl: {
                hd: BsCss.fontSize(BsCss.px(32)),
                tl: {
                  hd: BsCss.lineHeight(BsCss.px(37)),
                  tl: {
                    hd: BsCss.marginBottom(BsCss.px(10)),
                    tl: {
                      hd: BsCss.color(Theme__Color$PrezzeeRibbon.pink300),
                      tl: {
                        hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.desktop, {
                              hd: BsCss.fontSize(BsCss.px(38)),
                              tl: {
                                hd: BsCss.lineHeight(BsCss.px(41)),
                                tl: {
                                  hd: BsCss.marginBottom(BsCss.px(12)),
                                  tl: /* [] */0
                                }
                              }
                            }),
                        tl: {
                          hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.tablet, {
                                hd: BsCss.fontSize(BsCss.px(34)),
                                tl: {
                                  hd: BsCss.lineHeight(BsCss.px(41)),
                                  tl: {
                                    hd: BsCss.marginBottom(BsCss.px(11)),
                                    tl: /* [] */0
                                  }
                                }
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var firstTile_subtitle = Curry._1(BsCss.merge, {
      hd: titleBase,
      tl: {
        hd: subtitleBase,
        tl: {
          hd: Curry._1(BsCss.style, {
                hd: createFluidTypography(26, 32),
                tl: {
                  hd: BsCss.fontSize(BsCss.px(26)),
                  tl: {
                    hd: BsCss.lineHeight(BsCss.px(31)),
                    tl: {
                      hd: BsCss.color(Theme__Color$PrezzeeRibbon.accentBlue),
                      tl: {
                        hd: BsCss.fontFamily({
                              NAME: "custom",
                              VAL: "DM Serif Display"
                            }),
                        tl: {
                          hd: BsCss.fontStyle("italic"),
                          tl: {
                            hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.desktop, {
                                  hd: BsCss.fontSize(BsCss.px(32)),
                                  tl: {
                                    hd: BsCss.lineHeight(BsCss.px(41)),
                                    tl: /* [] */0
                                  }
                                }),
                            tl: {
                              hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.tablet, {
                                    hd: BsCss.fontSize(BsCss.px(29)),
                                    tl: {
                                      hd: BsCss.lineHeight(BsCss.px(36)),
                                      tl: /* [] */0
                                    }
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }),
          tl: /* [] */0
        }
      }
    });

var firstTile_background = Curry._1(BsCss.style, {
      hd: BsCss.backgroundColor(Theme__Color$PrezzeeRibbon.purple400),
      tl: /* [] */0
    });

var firstTile = {
  title: firstTile_title,
  subtitle: firstTile_subtitle,
  background: firstTile_background
};

var secondTile_title = Curry._1(BsCss.merge, {
      hd: titleBase,
      tl: {
        hd: Curry._1(BsCss.style, {
              hd: createFluidTypography(52, 70),
              tl: {
                hd: BsCss.fontSize(BsCss.px(32)),
                tl: {
                  hd: BsCss.marginBottom(BsCss.px(13)),
                  tl: {
                    hd: BsCss.color(Theme__Color$PrezzeeRibbon.pink300),
                    tl: {
                      hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.desktop, {
                            hd: BsCss.fontSize(BsCss.px(52)),
                            tl: {
                              hd: BsCss.lineHeight(BsCss.px(41)),
                              tl: {
                                hd: BsCss.marginBottom(BsCss.px(17)),
                                tl: /* [] */0
                              }
                            }
                          }),
                      tl: {
                        hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.tablet, {
                              hd: BsCss.fontSize(BsCss.px(46)),
                              tl: {
                                hd: BsCss.lineHeight(BsCss.px(37)),
                                tl: {
                                  hd: BsCss.marginBottom(BsCss.px(15)),
                                  tl: /* [] */0
                                }
                              }
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var secondTile_subtitle = Curry._1(BsCss.merge, {
      hd: titleBase,
      tl: {
        hd: subtitleBase,
        tl: {
          hd: Curry._1(BsCss.style, {
                hd: createFluidTypography(26, 28),
                tl: {
                  hd: BsCss.lineHeight(BsCss.px(30)),
                  tl: {
                    hd: BsCss.color(Theme__Color$PrezzeeRibbon.white),
                    tl: {
                      hd: BsCss.fontFamily({
                            NAME: "custom",
                            VAL: "DM Serif Display"
                          }),
                      tl: {
                        hd: BsCss.fontStyle("italic"),
                        tl: {
                          hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.desktop, {
                                hd: BsCss.fontSize(BsCss.px(28)),
                                tl: {
                                  hd: BsCss.lineHeight(BsCss.px(30)),
                                  tl: /* [] */0
                                }
                              }),
                          tl: {
                            hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.tablet, {
                                  hd: BsCss.fontSize(BsCss.px(25)),
                                  tl: {
                                    hd: BsCss.lineHeight(BsCss.px(27)),
                                    tl: /* [] */0
                                  }
                                }),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }),
          tl: /* [] */0
        }
      }
    });

var secondTile_background = Curry._1(BsCss.style, {
      hd: BsCss.backgroundColor(Theme__Color$PrezzeeRibbon.red400),
      tl: /* [] */0
    });

var secondTile = {
  title: secondTile_title,
  subtitle: secondTile_subtitle,
  background: secondTile_background
};

var thirdTile = {
  title: "",
  subtitle: "",
  background: ""
};

var fourthTile_title = Curry._1(BsCss.merge, {
      hd: titleBase,
      tl: {
        hd: Curry._1(BsCss.style, {
              hd: createFluidTypography(28, 40),
              tl: {
                hd: BsCss.fontSize(BsCss.px(30)),
                tl: {
                  hd: BsCss.lineHeight(BsCss.px(33)),
                  tl: {
                    hd: BsCss.marginBottom(BsCss.px(11)),
                    tl: {
                      hd: BsCss.color(Theme__Color$PrezzeeRibbon.red400),
                      tl: {
                        hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.desktop, {
                              hd: BsCss.marginBottom(BsCss.px(17)),
                              tl: /* [] */0
                            }),
                        tl: {
                          hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.tablet, {
                                hd: BsCss.fontSize(BsCss.px(27)),
                                tl: {
                                  hd: BsCss.lineHeight(BsCss.px(29)),
                                  tl: {
                                    hd: BsCss.marginBottom(BsCss.px(14)),
                                    tl: /* [] */0
                                  }
                                }
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var fourthTile_subtitle = Curry._1(BsCss.merge, {
      hd: titleBase,
      tl: {
        hd: subtitleBase,
        tl: {
          hd: Curry._1(BsCss.style, {
                hd: createFluidTypography(16, 20),
                tl: {
                  hd: BsCss.fontSize(BsCss.px(16)),
                  tl: {
                    hd: BsCss.lineHeight(BsCss.px(22)),
                    tl: {
                      hd: BsCss.color(Theme__Color$PrezzeeRibbon.purple400),
                      tl: {
                        hd: BsCss.fontFamily({
                              NAME: "custom",
                              VAL: "DM Serif Display"
                            }),
                        tl: {
                          hd: BsCss.fontStyle("italic"),
                          tl: {
                            hd: BsCss.textAlign("center"),
                            tl: {
                              hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.desktop, {
                                    hd: BsCss.fontSize(BsCss.px(20)),
                                    tl: {
                                      hd: BsCss.lineHeight(BsCss.px(26)),
                                      tl: /* [] */0
                                    }
                                  }),
                              tl: {
                                hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.tablet, {
                                      hd: BsCss.fontSize(BsCss.px(17)),
                                      tl: {
                                        hd: BsCss.lineHeight(BsCss.px(23)),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }),
          tl: /* [] */0
        }
      }
    });

var fourthTile_background = Curry._1(BsCss.style, {
      hd: BsCss.backgroundColor(Theme__Color$PrezzeeRibbon.lilac400),
      tl: /* [] */0
    });

var fourthTile = {
  title: fourthTile_title,
  subtitle: fourthTile_subtitle,
  background: fourthTile_background
};

var Styles = {
  section: section,
  contentContainer: contentContainer,
  heading: heading,
  description: description,
  iconImage: iconImage,
  createFluidTypography: createFluidTypography,
  titleBase: titleBase,
  subtitleBase: subtitleBase,
  firstTile: firstTile,
  secondTile: secondTile,
  thirdTile: thirdTile,
  fourthTile: fourthTile
};

function StatsTilesSection$Content(props) {
  var style = props.style;
  var subtitle = props.subtitle;
  var title = props.title;
  var tmp = title === "" ? null : JsxRuntime.jsx("h4", {
          children: title,
          className: style.title
        });
  var tmp$1 = subtitle === "" ? null : JsxRuntime.jsx("div", {
          children: JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
                children: subtitle
              }),
          className: style.subtitle
        });
  return JsxRuntime.jsx(KontentContextProvider.make, {
              children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                    children: JsxRuntime.jsxs("div", {
                          children: [
                            tmp,
                            tmp$1
                          ],
                          className: contentContainer
                        })
                  }),
              itemId: props.itemId,
              elementCodename: props.elementCodename
            });
}

var Content = {
  KontentContextConsumer: undefined,
  make: StatsTilesSection$Content
};

function StatsTilesSection(props) {
  var block = props.block;
  var description$1 = block.description;
  var title = block.title;
  var blockTitle = title === "" ? null : JsxRuntime.jsx("h2", {
          children: title,
          className: heading
        });
  var blockDescription = description$1 === "" ? null : JsxRuntime.jsx("div", {
          children: JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
                children: description$1
              }),
          className: description
        });
  var tiles = Belt_Array.mapWithIndex(block.stats_tiles, (function (idx, tile) {
          var smartLink = tile.smartLink;
          var background_image_mobile = tile.background_image_mobile;
          var background_image = tile.background_image;
          var style;
          switch (idx) {
            case 0 :
                style = firstTile;
                break;
            case 1 :
                style = secondTile;
                break;
            case 2 :
                style = thirdTile;
                break;
            case 3 :
                style = fourthTile;
                break;
            default:
              style = fourthTile;
          }
          var content = JsxRuntime.jsx(StatsTilesSection$Content, {
                title: tile.title,
                subtitle: tile.subtitle,
                style: style,
                itemId: smartLink.itemId,
                elementCodename: smartLink.codename
              });
          var backgroundImage = background_image !== undefined ? background_image.url : undefined;
          var backgroundImageMobile = background_image_mobile !== undefined ? background_image_mobile.url : undefined;
          var className;
          switch (idx) {
            case 0 :
                className = firstTile_background;
                break;
            case 1 :
                className = secondTile_background;
                break;
            case 2 :
                className = "";
                break;
            case 3 :
                className = fourthTile_background;
                break;
            default:
              className = fourthTile_background;
          }
          return JsxRuntime.jsx(StatsTile$PrezzeeRibbon.make, {
                      content: content,
                      backgroundImage: backgroundImage,
                      backgroundImageMobile: backgroundImageMobile,
                      className: className
                    }, String(idx));
        }));
  return JsxRuntime.jsxs("section", {
              children: [
                blockTitle,
                blockDescription,
                JsxRuntime.jsx(StatsTileBlock$PrezzeeRibbon.make, {
                      children: tiles
                    })
              ],
              className: section
            });
}

var make = StatsTilesSection;

export {
  Styles ,
  Content ,
  make ,
}
/* section Not a pure module */
