// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as Pino from "../../../bindings/Pino.bs.js";
import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Regex from "../../../utils/Regex.bs.js";
import * as React from "react";
import * as CmsImage from "../../CmsImage.bs.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as IndexStyles from "./IndexStyles.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Vimeo$PrezzeeRibbon from "@prezzee/ribbon/src/bindings/Vimeo.bs.js";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as Tagline$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Tagline/Tagline.bs.js";
import * as KontentContextProvider from "./KontentContextProvider.bs.js";
import * as RichText$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RichText/RichText.bs.js";
import * as Theme__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Responsive.bs.js";
import * as VimeoPlayer__Video$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/VimeoPlayer/VimeoPlayer__Video.bs.js";
import * as VideoTextPromoBlock$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/VideoTextPromoBlock/VideoTextPromoBlock.bs.js";
import * as VimeoPlayer__Player$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/VimeoPlayer/VimeoPlayer__Player.bs.js";
import * as VideoTextPromoBlock__Video$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/VideoTextPromoBlock/VideoTextPromoBlock__Video.bs.js";
import * as VideoTextPromoBlock__Heading$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/VideoTextPromoBlock/VideoTextPromoBlock__Heading.bs.js";
import * as VimeoPlayer__MobileAwareVideo$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/VimeoPlayer/VimeoPlayer__MobileAwareVideo.bs.js";
import * as VideoTextPromoBlock__SideImage$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/VideoTextPromoBlock/VideoTextPromoBlock__SideImage.bs.js";
import * as VideoTextPromoBlock__Background$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/VideoTextPromoBlock/VideoTextPromoBlock__Background.bs.js";
import * as VideoTextPromoBlock__Description$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/VideoTextPromoBlock/VideoTextPromoBlock__Description.bs.js";
import * as VideoTextPromoBlock__TextWrapper$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/VideoTextPromoBlock/VideoTextPromoBlock__TextWrapper.bs.js";
import * as VideoTextPromoBlock__MediaWrapper$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/VideoTextPromoBlock/VideoTextPromoBlock__MediaWrapper.bs.js";
import * as VideoTextPromoBlock__VideoOverlay$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/VideoTextPromoBlock/VideoTextPromoBlock__VideoOverlay.bs.js";
import * as VideoTextPromoBlock__VideoContainer$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/VideoTextPromoBlock/VideoTextPromoBlock__VideoContainer.bs.js";

var container = Curry._1(BsCss.style, {
      hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.desktop, {
            hd: BsCss.marginTop(BsCss.rem(8.4375)),
            tl: {
              hd: BsCss.marginBottom(BsCss.rem(6.5625)),
              tl: /* [] */0
            }
          }),
      tl: {
        hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.tablet, {
              hd: BsCss.marginTop(BsCss.rem(4.125)),
              tl: {
                hd: BsCss.marginBottom(BsCss.rem(4.5)),
                tl: /* [] */0
              }
            }),
        tl: {
          hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.tabletSm, {
                hd: BsCss.paddingLeft(BsCss.rem(0.375)),
                tl: {
                  hd: BsCss.paddingRight(BsCss.rem(0.375)),
                  tl: /* [] */0
                }
              }),
          tl: {
            hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.mobile, {
                  hd: BsCss.marginTop(BsCss.rem(2.75)),
                  tl: {
                    hd: BsCss.marginBottom(BsCss.rem(3.6875)),
                    tl: /* [] */0
                  }
                }),
            tl: /* [] */0
          }
        }
      }
    });

var description = Curry._1(BsCss.style, {
      hd: BsCss.selector("a", {
            hd: BsCss.color(BsCss.hex("1A65F0")),
            tl: {
              hd: BsCss.textDecoration("underline"),
              tl: {
                hd: BsCss.hover({
                      hd: BsCss.color(BsCss.hex("1E54B7")),
                      tl: /* [] */0
                    }),
                tl: {
                  hd: BsCss.active({
                        hd: BsCss.color(BsCss.hex("102C60")),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: BsCss.visited({
                          hd: BsCss.color(BsCss.hex("787878")),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }),
      tl: /* [] */0
    });

var Styles = {
  container: container,
  description: description
};

function VideoTextPromoSection(props) {
  var block = props.block;
  var match = React.useReducer((function (x, param) {
          return x + 1 | 0;
        }), 0);
  var smartLink = block.smartLink;
  var elementCodename = smartLink.codename;
  var itemId = smartLink.itemId;
  var side_image = block.side_image;
  var video_poster = block.video_poster;
  var mobile_video = block.mobile_video;
  var video = block.video;
  var forceUpdate = match[1];
  var taglineStr = block.tagline;
  var heading = JsxRuntime.jsx("h2", {
        children: block.title
      });
  var description$1 = JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
        children: block.description
      });
  var videoUrl = video !== undefined ? video : "";
  var mobileUrl = mobile_video !== undefined ? mobile_video : "";
  var videoImageFallback;
  if (video_poster !== undefined) {
    var smartLink$1 = video_poster.smartLink;
    videoImageFallback = JsxRuntime.jsx(KontentContextProvider.make, {
          children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                children: JsxRuntime.jsx(CmsImage.make, {
                      src: video_poster.url,
                      alt: video_poster.description,
                      width: video_poster.width,
                      height: video_poster.height,
                      sizes: "" + Theme__Responsive$PrezzeeRibbon.mobile + " 170px, 220px"
                    })
              }),
          itemId: smartLink$1.itemId,
          elementCodename: smartLink$1.codename
        });
  } else {
    videoImageFallback = null;
  }
  var sideImage;
  if (side_image !== undefined) {
    var smartLink$2 = side_image.smartLink;
    sideImage = JsxRuntime.jsx(KontentContextProvider.make, {
          children: JsxRuntime.jsx(VideoTextPromoBlock__SideImage$PrezzeeRibbon.make, {
                children: JsxRuntime.jsx(CmsImage.make, {
                      src: side_image.url,
                      alt: side_image.description,
                      width: side_image.width,
                      height: side_image.height,
                      sizes: "" + Theme__Responsive$PrezzeeRibbon.mobile + " 50vw, $(tabletOrMobile) 230px, 366px"
                    })
              }),
          itemId: smartLink$2.itemId,
          elementCodename: smartLink$2.codename
        });
  } else {
    sideImage = null;
  }
  var validDesktopUrl = Regex.vimeoRegex.test(videoUrl);
  var validMobileUrl = Regex.vimeoRegex.test(mobileUrl);
  var invalidMsg = "is not a valid vimeo url.";
  if (!validDesktopUrl && Belt_Option.isSome(video)) {
    Pino.logger.info({
          type: "Video Text Promo Section Desktop Video Url"
        }, "info: \"" + videoUrl + "\" " + invalidMsg + "");
  }
  if (!validMobileUrl && Belt_Option.isSome(mobile_video)) {
    Pino.logger.info({
          type: "Video Text Promo Section Mobile Video Url"
        }, "info: \"" + mobileUrl + "\" " + invalidMsg + "");
  }
  var playerOptions = Vimeo$PrezzeeRibbon.Player.options(undefined, true, undefined, undefined, undefined, false, undefined, block.loop_video, true, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined);
  var player = JsxRuntime.jsx(VimeoPlayer__Player$PrezzeeRibbon.make, {
        id: "video-text-promo-player",
        playerOptions: playerOptions
      });
  var desktopVideo = JsxRuntime.jsx(VimeoPlayer__Video$PrezzeeRibbon.make, {
        videoUrl: videoUrl,
        children: player
      });
  var mobileVideo = JsxRuntime.jsx(VimeoPlayer__MobileAwareVideo$PrezzeeRibbon.make, {
        videoUrl: videoUrl,
        mobileUrl: mobileUrl,
        children: player
      });
  var video$1 = JsxRuntime.jsxs(React.Fragment, {
        children: [
          validDesktopUrl && validMobileUrl ? mobileVideo : (
              validDesktopUrl ? desktopVideo : null
            ),
          videoImageFallback
        ]
      }, match[0].toString());
  React.useEffect((function () {
          Curry._1(forceUpdate, undefined);
        }), []);
  return JsxRuntime.jsx("section", {
              children: JsxRuntime.jsx(VideoTextPromoBlock$PrezzeeRibbon.make, {
                    children: JsxRuntime.jsxs(VideoTextPromoBlock__Background$PrezzeeRibbon.make, {
                          children: [
                            JsxRuntime.jsxs(VideoTextPromoBlock__MediaWrapper$PrezzeeRibbon.make, {
                                  children: [
                                    sideImage,
                                    JsxRuntime.jsx(KontentContextProvider.make, {
                                          children: JsxRuntime.jsxs(VideoTextPromoBlock__VideoContainer$PrezzeeRibbon.make, {
                                                children: [
                                                  JsxRuntime.jsx(VideoTextPromoBlock__VideoOverlay$PrezzeeRibbon.make, {
                                                        children: JsxRuntime.jsx("img", {
                                                              alt: "Phone frame",
                                                              src: "/images/videoTextPromoSection/phone_overlay.png"
                                                            })
                                                      }),
                                                  JsxRuntime.jsx(VideoTextPromoBlock__Video$PrezzeeRibbon.make, {
                                                        children: video$1
                                                      })
                                                ]
                                              }),
                                          itemId: itemId,
                                          elementCodename: elementCodename
                                        })
                                  ]
                                }),
                            JsxRuntime.jsx(KontentContextProvider.make, {
                                  children: JsxRuntime.jsxs(VideoTextPromoBlock__TextWrapper$PrezzeeRibbon.make, {
                                        children: [
                                          JsxRuntime.jsx(Tagline$PrezzeeRibbon.make, {
                                                children: taglineStr
                                              }),
                                          JsxRuntime.jsx(VideoTextPromoBlock__Heading$PrezzeeRibbon.make, {
                                                children: heading
                                              }),
                                          JsxRuntime.jsx(VideoTextPromoBlock__Description$PrezzeeRibbon.make, {
                                                children: description$1,
                                                className: description
                                              })
                                        ]
                                      }),
                                  itemId: itemId,
                                  elementCodename: elementCodename
                                })
                          ]
                        })
                  }),
              className: Cn.make([
                    IndexStyles.section,
                    container
                  ])
            });
}

var KontentContextConsumer;

var Background;

var MediaWrapper;

var SideImage;

var VideoContainer;

var VideoOverlay;

var Video;

var TextWrapper;

var Heading;

var Description;

var make = VideoTextPromoSection;

export {
  KontentContextConsumer ,
  Background ,
  MediaWrapper ,
  SideImage ,
  VideoContainer ,
  VideoOverlay ,
  Video ,
  TextWrapper ,
  Heading ,
  Description ,
  Styles ,
  make ,
}
/* container Not a pure module */
