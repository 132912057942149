// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as FormHelpers from "../../../utils/FormHelpers.bs.js";
import * as IndexStyles from "./IndexStyles.bs.js";
import * as StaticImage from "../../StaticImage.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Button$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Button/Button.bs.js";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as KontentContextProvider from "./KontentContextProvider.bs.js";
import * as NewsletterSubscription from "../../library/NewsletterSubscription.bs.js";
import * as RichText$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RichText/RichText.bs.js";
import * as TextField$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/TextField/TextField.bs.js";
import * as EmailSignUp$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/EmailSignUp/EmailSignUp.bs.js";
import * as Theme__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Responsive.bs.js";
import * as EmailSignUp__CtaLine$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/EmailSignUp/EmailSignUp__CtaLine.bs.js";
import * as EmailSignUp__Heading$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/EmailSignUp/EmailSignUp__Heading.bs.js";
import * as EmailSignUp__Success$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/EmailSignUp/EmailSignUp__Success.bs.js";
import * as EmailSignUp__BodyText$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/EmailSignUp/EmailSignUp__BodyText.bs.js";

var input = Curry._1(BsCss.style, {
      hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.tabletSmUp, {
            hd: BsCss.width(BsCss.px(415)),
            tl: /* [] */0
          }),
      tl: /* [] */0
    });

var Styles = {
  input: input
};

function make(param) {
  var block = param.block;
  var button = block.button;
  var smartLink = button.smartLink;
  var form = param.form;
  return JsxRuntime.jsx("form", {
              children: JsxRuntime.jsxs(EmailSignUp__CtaLine$PrezzeeRibbon.make, {
                    children: [
                      JsxRuntime.jsx(TextField$PrezzeeRibbon.make, {
                            className: input,
                            label: block.input_box_placeholder_text,
                            name: "email_address",
                            type_: "email",
                            error: Belt_Option.isSome(FormHelpers.getFieldError(form.errors, "email_address")),
                            helperText: FormHelpers.getFieldError(form.errors, "email_address"),
                            inputRef: Caml_option.some(Curry._1(form.register, NewsletterSubscription.newsletterInput))
                          }),
                      JsxRuntime.jsx(Button$PrezzeeRibbon.make, {
                            children: JsxRuntime.jsx(KontentContextProvider.make, {
                                  children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                                        children: JsxRuntime.jsx("button", {
                                              children: button.cta_text,
                                              disabled: form.formState.isSubmitted,
                                              type: "submit"
                                            })
                                      }),
                                  itemId: smartLink.itemId,
                                  elementCodename: smartLink.codename
                                }),
                            fill: button.fill,
                            colour: button.colour,
                            variant: button.variant,
                            shadow: /* Normal */1
                          })
                    ]
                  }),
              onSubmit: Curry._2(form.handleSubmit, param.onSubmit, FormHelpers.onError)
            });
}

var NewsletterSection$NewsletterForm = make;

var NewsletterForm = {
  Styles: Styles,
  make: NewsletterSection$NewsletterForm
};

var successImg = Curry._1(BsCss.style, {
      hd: BsCss.important(BsCss.width(BsCss.px(38))),
      tl: /* [] */0
    });

function getBackgroundStyle(desktop, tablet, mobile) {
  var fallbackBg = desktop !== undefined ? desktop.url : (
      tablet !== undefined ? tablet.url : (
          mobile !== undefined ? mobile.url : "/images/ribbon.png"
        )
    );
  var bgDesktop = desktop !== undefined ? desktop.url : fallbackBg;
  var bgTablet = tablet !== undefined ? tablet.url : fallbackBg;
  var bgMobile = mobile !== undefined ? mobile.url : (
      tablet !== undefined ? tablet.url : fallbackBg
    );
  return EmailSignUp$PrezzeeRibbon.Styles.backgroundImages(bgDesktop, bgTablet, bgMobile);
}

var Styles$1 = {
  successImg: successImg,
  getBackgroundStyle: getBackgroundStyle
};

function NewsletterSection(props) {
  var store = props.store;
  var block = props.block;
  var store$1 = store !== undefined ? store : "consumer";
  var smartLink = block.smartLink;
  var backgroundStyle = getBackgroundStyle(block.background_image, block.background_image_tablet, block.background_image_mobile);
  var successElement = JsxRuntime.jsxs(EmailSignUp__Success$PrezzeeRibbon.make, {
        children: [
          JsxRuntime.jsx(StaticImage.make, {
                src: "/images/confetti.png",
                alt: "Confetti",
                width: 38,
                height: 37,
                className: successImg
              }),
          JsxRuntime.jsx("p", {
                children: block.success_message
              })
        ]
      });
  var newsletterFormBuilder = function (input) {
    return make({
                block: block,
                form: input.form,
                onSubmit: input.onSubmit
              });
  };
  return JsxRuntime.jsx(KontentContextProvider.make, {
              children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                    children: JsxRuntime.jsx("section", {
                          children: JsxRuntime.jsxs(EmailSignUp$PrezzeeRibbon.make, {
                                children: [
                                  JsxRuntime.jsx(EmailSignUp__Heading$PrezzeeRibbon.make, {
                                        children: block.title
                                      }),
                                  JsxRuntime.jsx(EmailSignUp__BodyText$PrezzeeRibbon.make, {
                                        children: JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
                                              children: block.description
                                            })
                                      }),
                                  JsxRuntime.jsx(NewsletterSubscription.make, {
                                        formComponent: newsletterFormBuilder,
                                        successElement: successElement,
                                        store: store$1
                                      })
                                ],
                                className: backgroundStyle
                              }),
                          className: IndexStyles.section
                        })
                  }),
              itemId: smartLink.itemId,
              elementCodename: smartLink.codename
            });
}

var KontentContextConsumer;

var $$Image;

var make$1 = NewsletterSection;

export {
  KontentContextConsumer ,
  NewsletterForm ,
  $$Image ,
  Styles$1 as Styles,
  make$1 as make,
}
/* input Not a pure module */
