// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as CmsImage from "../../CmsImage.bs.js";
import Link from "next/link";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as IndexStyles from "../index/IndexStyles.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as KontentContextProvider from "../index/KontentContextProvider.bs.js";
import * as RichText$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RichText/RichText.bs.js";
import * as Theme__Color$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Color.bs.js";
import * as TeamMemberCard$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/TeamMembers/TeamMemberCard.bs.js";
import * as TeamMembersBlock$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/TeamMembers/TeamMembersBlock.bs.js";
import * as Theme__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Responsive.bs.js";

var section = Curry._1(BsCss.merge, {
      hd: IndexStyles.section,
      tl: {
        hd: Curry._1(BsCss.style, {
              hd: BsCss.maxWidth(BsCss.px(1240)),
              tl: {
                hd: BsCss.marginTop(BsCss.px(100)),
                tl: {
                  hd: BsCss.paddingLeft(BsCss.px(20)),
                  tl: {
                    hd: BsCss.paddingRight(BsCss.px(20)),
                    tl: {
                      hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.belowLargeScreen, {
                            hd: BsCss.paddingLeft(BsCss.px(20)),
                            tl: {
                              hd: BsCss.paddingRight(BsCss.px(20)),
                              tl: /* [] */0
                            }
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var heading = Curry._1(BsCss.style, {
      hd: BsCss.fontWeight("semiBold"),
      tl: {
        hd: BsCss.color(Theme__Color$PrezzeeRibbon.Personal.charcoal),
        tl: {
          hd: BsCss.fontSize(BsCss.px(28)),
          tl: {
            hd: BsCss.lineHeight(BsCss.px(36)),
            tl: {
              hd: BsCss.marginBottom("zero"),
              tl: {
                hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.desktop, {
                      hd: BsCss.fontSize(BsCss.px(40)),
                      tl: {
                        hd: BsCss.lineHeight(BsCss.px(50)),
                        tl: {
                          hd: BsCss.marginBottom("zero"),
                          tl: /* [] */0
                        }
                      }
                    }),
                tl: {
                  hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.tablet, {
                        hd: BsCss.fontSize(BsCss.px(36)),
                        tl: {
                          hd: BsCss.lineHeight(BsCss.px(46)),
                          tl: {
                            hd: BsCss.marginBottom("zero"),
                            tl: /* [] */0
                          }
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var Styles = {
  section: section,
  heading: heading
};

function MeetTheTeam(props) {
  var block = props.block;
  var smartLink = block.smartLink;
  var title = block.title;
  var blockTitle = title === "" ? null : JsxRuntime.jsx("h2", {
          children: title,
          className: heading
        });
  var perRow;
  switch (block.cards_per_row) {
    case "five" :
        perRow = /* Five */2;
        break;
    case "four" :
        perRow = /* Four */1;
        break;
    case "three" :
        perRow = /* Three */0;
        break;
    default:
      perRow = /* Four */1;
  }
  var cards = Belt_Array.mapWithIndex(block.team_member_cards, (function (idx, cardContent) {
          var key = String(idx);
          var smartLink = cardContent.smartLink;
          var link = cardContent.link;
          var name = cardContent.name;
          var photo_front = cardContent.photo_front;
          var photoFront = JsxRuntime.jsx(CmsImage.make, {
                src: photo_front.url,
                alt: name,
                width: photo_front.width,
                height: photo_front.height,
                sizes: "" + Theme__Responsive$PrezzeeRibbon.tabletSm + " 100vw, " + Theme__Responsive$PrezzeeRibbon.tabletOrMobile + " 482px, 286px"
              });
          var photoBack = Belt_Option.mapWithDefault(cardContent.photo_back, undefined, (function (pb) {
                  return Caml_option.some(JsxRuntime.jsx(CmsImage.make, {
                                  src: pb.url,
                                  alt: name,
                                  width: pb.width,
                                  height: pb.height,
                                  sizes: "" + Theme__Responsive$PrezzeeRibbon.tabletSm + " 100vw, " + Theme__Responsive$PrezzeeRibbon.tabletOrMobile + " 482px, 286px"
                                }));
                }));
          var nameEl = JsxRuntime.jsx("h2", {
                children: name
              });
          var titleEl = JsxRuntime.jsx("h2", {
                children: cardContent.title
              });
          var descEl = JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
                children: cardContent.description
              });
          var card = link !== undefined ? JsxRuntime.jsx(Link, {
                  href: link,
                  children: JsxRuntime.jsx("a", {
                        children: JsxRuntime.jsx(TeamMemberCard$PrezzeeRibbon.make, {
                              photoFront: photoFront,
                              photoBack: photoBack,
                              name: nameEl,
                              title: titleEl,
                              description: descEl
                            })
                      })
                }) : JsxRuntime.jsx(TeamMemberCard$PrezzeeRibbon.make, {
                  photoFront: photoFront,
                  photoBack: photoBack,
                  name: nameEl,
                  title: titleEl,
                  description: descEl
                });
          return JsxRuntime.jsx(KontentContextProvider.make, {
                      children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                            children: JsxRuntime.jsx("div", {
                                  children: card
                                })
                          }),
                      itemId: smartLink.itemId,
                      elementCodename: smartLink.codename
                    }, key);
        }));
  return JsxRuntime.jsx(KontentContextProvider.make, {
              children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                    children: JsxRuntime.jsxs("section", {
                          children: [
                            blockTitle,
                            JsxRuntime.jsx(TeamMembersBlock$PrezzeeRibbon.make, {
                                  children: cards,
                                  perRow: perRow
                                })
                          ],
                          className: section
                        })
                  }),
              itemId: smartLink.itemId,
              elementCodename: smartLink.codename
            });
}

var Link$1;

var make = MeetTheTeam;

export {
  Link$1 as Link,
  Styles ,
  make ,
}
/* section Not a pure module */
