// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as CmsImage from "../../CmsImage.bs.js";
import Link from "next/link";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as IndexStyles from "./IndexStyles.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ImageCollectionUtil from "../business/ImageCollectionUtil.bs.js";
import * as KontentContextProvider from "./KontentContextProvider.bs.js";
import * as PromoTile$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/PromoTiles/PromoTile.bs.js";
import * as PromoTiles$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/PromoTiles/PromoTiles.bs.js";
import * as DeviceSizeImage$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/DeviceSizeImage/DeviceSizeImage.bs.js";
import * as PromoTile__Image$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/PromoTiles/PromoTile__Image.bs.js";
import * as Theme__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Responsive.bs.js";
import * as PromoTile__Content$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/PromoTiles/PromoTile__Content.bs.js";

var section = Curry._1(BsCss.style, {
      hd: BsCss.marginTop(BsCss.rem(1)),
      tl: {
        hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.desktop, {
              hd: BsCss.marginBottom(BsCss.rem(8.4375)),
              tl: /* [] */0
            }),
        tl: {
          hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.tablet, {
                hd: BsCss.marginBottom(BsCss.rem(6.25)),
                tl: /* [] */0
              }),
          tl: {
            hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.mobile, {
                  hd: BsCss.marginBottom(BsCss.rem(5)),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var link = Curry._1(BsCss.style, {
      hd: BsCss.display("block"),
      tl: {
        hd: BsCss.width(BsCss.pct(100)),
        tl: /* [] */0
      }
    });

var title = Curry._1(BsCss.style, {
      hd: BsCss.fontSize(BsCss.px(32)),
      tl: {
        hd: BsCss.lineHeight(BsCss.px(40)),
        tl: {
          hd: BsCss.fontWeight({
                NAME: "num",
                VAL: 600
              }),
          tl: /* [] */0
        }
      }
    });

var bg = Curry._1(BsCss.style, {
      hd: BsCss.selector("img", {
            hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.mobileUp, {
                  hd: BsCss.width("auto"),
                  tl: {
                    hd: BsCss.maxWidth("none"),
                    tl: /* [] */0
                  }
                }),
            tl: /* [] */0
          }),
      tl: /* [] */0
    });

var Styles = {
  section: section,
  link: link,
  title: title,
  bg: bg
};

function getTileStyle(bgColour) {
  if (bgColour === "") {
    return "";
  } else {
    return Curry._1(BsCss.style, {
                hd: BsCss.important(BsCss.unsafe("backgroundColor", bgColour)),
                tl: /* [] */0
              });
  }
}

function getTextStyle(textColour, desktopTextWidth) {
  var colour = textColour === "" ? "" : Curry._1(BsCss.style, {
          hd: BsCss.important(BsCss.unsafe("color", textColour)),
          tl: /* [] */0
        });
  var width = desktopTextWidth === "" ? "" : Curry._1(BsCss.style, {
          hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.desktop, {
                hd: BsCss.important(BsCss.unsafe("maxWidth", desktopTextWidth)),
                tl: /* [] */0
              }),
          tl: /* [] */0
        });
  return Curry._1(BsCss.merge, {
              hd: colour,
              tl: {
                hd: width,
                tl: /* [] */0
              }
            });
}

function getIconStyle(iconColour) {
  if (iconColour === "") {
    return "";
  } else {
    return Curry._1(BsCss.style, {
                hd: BsCss.important(BsCss.unsafe("color", iconColour)),
                tl: /* [] */0
              });
  }
}

var Builder = {
  getTileStyle: getTileStyle,
  getTextStyle: getTextStyle,
  getIconStyle: getIconStyle
};

function PromoSection(props) {
  var tileComponents = Belt_Array.mapWithIndex(props.block, (function (idx, t) {
          var key = String(idx);
          var smartLink = t.smartLink;
          var image = t.image;
          var tileStyle = getTileStyle(t.background_colour);
          var textStyle = getTextStyle(t.text_colour, t.desktop_text_width);
          var iconStyle = getIconStyle(t.icon_colour);
          var match = t.graphic;
          var tmp;
          if (match !== undefined) {
            var src = match.url;
            if (src !== "") {
              var width = match.width;
              tmp = JsxRuntime.jsx(CmsImage.make, {
                    src: src,
                    alt: match.description,
                    width: width,
                    height: match.height,
                    sizes: "" + String(width) + "px"
                  });
            } else {
              tmp = null;
            }
          } else {
            tmp = null;
          }
          var content = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  tmp,
                  JsxRuntime.jsx("h3", {
                        children: t.title,
                        className: title
                      })
                ]
              });
          var displayImage;
          if (image !== undefined) {
            if (image.NAME === "DeviceSizeImage") {
              var images = image.VAL;
              var imageD = ImageCollectionUtil.Helpers.getAssetOptionWithSizes(images.desktop_image, "300px");
              var imageT = ImageCollectionUtil.Helpers.getAssetOptionWithSizes(images.tablet_image, "300px");
              var imageM = ImageCollectionUtil.Helpers.getAssetOptionWithSizes(images.mobile_image, "300px");
              displayImage = JsxRuntime.jsx(PromoTile__Image$PrezzeeRibbon.make, {
                    children: JsxRuntime.jsx(DeviceSizeImage$PrezzeeRibbon.make, {
                          desktop: imageD,
                          smallScreen: undefined,
                          tablet: imageT,
                          mobile: imageM
                        }),
                    className: bg
                  });
            } else {
              var img = image.VAL;
              displayImage = JsxRuntime.jsx(PromoTile__Image$PrezzeeRibbon.make, {
                    children: JsxRuntime.jsx(CmsImage.make, {
                          src: img.url,
                          alt: img.description,
                          width: img.width,
                          height: img.height,
                          sizes: "300px"
                        }),
                    className: bg
                  });
            }
          } else {
            displayImage = null;
          }
          return JsxRuntime.jsx(Link, {
                      href: t.link,
                      prefetch: false,
                      children: JsxRuntime.jsx("a", {
                            children: JsxRuntime.jsx(KontentContextProvider.make, {
                                  children: JsxRuntime.jsxs(PromoTile$PrezzeeRibbon.make, {
                                        children: [
                                          displayImage,
                                          JsxRuntime.jsx(PromoTile__Content$PrezzeeRibbon.make, {
                                                children: content,
                                                className: textStyle
                                              })
                                        ],
                                        className: tileStyle,
                                        iconStyle: iconStyle
                                      }),
                                  itemId: smartLink.itemId,
                                  elementCodename: smartLink.codename
                                }),
                            className: link
                          })
                    }, key);
        }));
  return JsxRuntime.jsx("section", {
              children: JsxRuntime.jsx(PromoTiles$PrezzeeRibbon.make, {
                    children: tileComponents
                  }),
              className: Cn.make([
                    IndexStyles.section,
                    section
                  ])
            });
}

var PromoTiles;

var PromoTile;

var $$Image;

var Content;

var DeviceSizeImage;

var ImageCollectionHelpers;

var make = PromoSection;

export {
  PromoTiles ,
  PromoTile ,
  $$Image ,
  Content ,
  DeviceSizeImage ,
  ImageCollectionHelpers ,
  Styles ,
  Builder ,
  make ,
}
/* section Not a pure module */
