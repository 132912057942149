// Generated by ReScript, PLEASE EDIT WITH CARE

import TrustpilotWidgetSectionTsx from "./TrustpilotWidgetSection.tsx";

var make = TrustpilotWidgetSectionTsx;

export {
  make ,
}
/* make Not a pure module */
