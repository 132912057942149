// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as React from "react";
import * as CmsImage from "../../CmsImage.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as IndexStyles from "./IndexStyles.bs.js";
import * as LayoutDataContext from "../../../utils/LayoutDataContext.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Text$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Text/Text.bs.js";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as KontentContextProvider from "./KontentContextProvider.bs.js";
import * as RichText$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RichText/RichText.bs.js";
import * as ThemeContext$PrezzeeRibbon from "@prezzee/ribbon/src/context/ThemeContext/ThemeContext.bs.js";
import * as Theme__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Responsive.bs.js";
import * as DownloadAppsBanner$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/DownloadAppsBanner/DownloadAppsBanner.bs.js";

function container(bgColor) {
  return Curry._1(BsCss.style, {
              hd: BsCss.width(BsCss.pct(100.0)),
              tl: {
                hd: BsCss.background({
                      NAME: "linearGradient",
                      VAL: [
                        {
                          NAME: "deg",
                          VAL: 0
                        },
                        [
                          [
                            BsCss.pct(55),
                            bgColor
                          ],
                          [
                            BsCss.pct(45),
                            "transparent"
                          ]
                        ]
                      ]
                    }),
                tl: {
                  hd: BsCss.paddingBottom(BsCss.rem(1.375)),
                  tl: /* [] */0
                }
              }
            });
}

var appBadge = Curry._1(BsCss.style, {
      hd: BsCss.important(BsCss.height(BsCss.px(52))),
      tl: /* [] */0
    });

var Styles = {
  container: container,
  appBadge: appBadge
};

function makeBadge(index, badge) {
  var smartLink = badge.smartLink;
  var elementCodename = smartLink.codename;
  var itemId = smartLink.itemId;
  var app_store_download_link = badge.app_store_download_link;
  var app_store_image = badge.app_store_image;
  var image;
  if (app_store_image !== undefined) {
    var height = app_store_image.height;
    var width = app_store_image.width;
    image = JsxRuntime.jsx(CmsImage.make, {
          src: app_store_image.url,
          alt: app_store_image.description,
          width: width !== 0 ? width : 156,
          height: height !== 0 ? height : 52,
          className: appBadge
        });
  } else {
    image = null;
  }
  if (app_store_download_link === "") {
    return JsxRuntime.jsx(KontentContextProvider.make, {
                children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("span", {
                                  children: image
                                })
                          })
                    }),
                itemId: itemId,
                elementCodename: elementCodename
              }, String(index));
  } else {
    return JsxRuntime.jsx(KontentContextProvider.make, {
                children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                      children: JsxRuntime.jsx("a", {
                            children: image,
                            title: badge.app_store_name,
                            href: app_store_download_link
                          })
                    }),
                itemId: itemId,
                elementCodename: elementCodename
              }, String(index));
  }
}

function makeBlock(index, block) {
  var smartLink = block.smartLink;
  var icon = block.icon;
  var icon$1;
  if (icon !== undefined) {
    var height = icon.height;
    var width = icon.width;
    icon$1 = JsxRuntime.jsx(KontentContextProvider.make, {
          children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                children: JsxRuntime.jsx(CmsImage.make, {
                      src: icon.url,
                      alt: icon.description,
                      width: width !== 0 ? width : 32,
                      height: height !== 0 ? height : 32,
                      sizes: "32px"
                    })
              }),
          itemId: smartLink.itemId,
          elementCodename: smartLink.codename
        });
  } else {
    icon$1 = null;
  }
  return JsxRuntime.jsxs(React.Fragment, {
              children: [
                icon$1,
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Text$PrezzeeRibbon.make, {
                            children: block.title
                          })
                    })
              ]
            }, String(index));
}

function getBackgroundImage(image) {
  if (image !== undefined) {
    return JsxRuntime.jsx(CmsImage.make, {
                src: image.url,
                alt: image.description,
                width: image.width,
                height: image.height,
                sizes: "" + Theme__Responsive$PrezzeeRibbon.smallScreenOrMobile + " 100vw, 700px"
              });
  } else {
    return null;
  }
}

function getDescription(description) {
  return JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
              children: description
            });
}

function getBadges(badges) {
  return Belt_Array.mapWithIndex(badges, makeBadge);
}

function getBlocks(blocks) {
  return Belt_Array.mapWithIndex(blocks, makeBlock);
}

var Content = {
  makeBadge: makeBadge,
  makeBlock: makeBlock,
  getBackgroundImage: getBackgroundImage,
  getDescription: getDescription,
  getBadges: getBadges,
  getBlocks: getBlocks
};

function DownloadAppsSection(props) {
  var block = props.block;
  var smartLink = block.smartLink;
  var theme = ThemeContext$PrezzeeRibbon.useTheme(undefined);
  var state = LayoutDataContext.useContext(undefined);
  var footer = Belt_Option.flatMap(state, (function (s) {
          return s.footer;
        }));
  var bgColor = Belt_Option.mapWithDefault(footer, theme.components.footer.backgroundColor, (function (s) {
          return s.theme.backgroundColor;
        }));
  return JsxRuntime.jsx("section", {
              children: JsxRuntime.jsx(KontentContextProvider.make, {
                    children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                          children: JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx(DownloadAppsBanner$PrezzeeRibbon.make, {
                                      blocks: Belt_Array.mapWithIndex(block.selling_points, makeBlock),
                                      background: getBackgroundImage(block.background_image),
                                      mobileBackground: getBackgroundImage(block.mobile_background_image),
                                      badges: Belt_Array.mapWithIndex(block.app_stores, makeBadge),
                                      description: getDescription(block.description),
                                      heading: block.title
                                    }),
                                className: IndexStyles.section
                              })
                        }),
                    itemId: smartLink.itemId,
                    elementCodename: smartLink.codename
                  }),
              className: container(bgColor)
            });
}

var KontentContextConsumer;

var make = DownloadAppsSection;

export {
  KontentContextConsumer ,
  Styles ,
  Content ,
  make ,
}
/* appBadge Not a pure module */
