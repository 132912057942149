// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as CmsImage from "../../CmsImage.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as KontentContextProvider from "../index/KontentContextProvider.bs.js";
import * as DeviceSizeImage$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/DeviceSizeImage/DeviceSizeImage.bs.js";

function cmsImg(w) {
  return Curry._1(BsCss.style, {
              hd: BsCss.width(BsCss.px(w)),
              tl: {
                hd: BsCss.height("auto"),
                tl: /* [] */0
              }
            });
}

var Styles = {
  cmsImg: cmsImg
};

function getImageOption(opt) {
  return Belt_Option.mapWithDefault(opt, undefined, (function (img) {
                var width = img.width;
                return Caml_option.some(JsxRuntime.jsx(CmsImage.make, {
                                src: img.url,
                                alt: img.description,
                                width: width,
                                height: img.height,
                                className: cmsImg(width),
                                sizes: "" + String(width) + "px"
                              }));
              }));
}

function getAssetOption(opt) {
  return Belt_Option.mapWithDefault(opt, undefined, (function (img) {
                var width = img.width;
                return Caml_option.some(JsxRuntime.jsx(CmsImage.make, {
                                src: img.url,
                                alt: img.description,
                                width: width,
                                height: img.height,
                                className: cmsImg(width),
                                sizes: "" + String(width) + "px"
                              }));
              }));
}

function getImageOptionWithSizes(opt, sizes) {
  return Belt_Option.mapWithDefault(opt, undefined, (function (img) {
                return Caml_option.some(JsxRuntime.jsx(CmsImage.make, {
                                src: img.url,
                                alt: img.description,
                                width: img.width,
                                height: img.height,
                                sizes: sizes
                              }));
              }));
}

function getAssetOptionWithSizes(opt, sizes) {
  return Belt_Option.mapWithDefault(opt, undefined, (function (img) {
                return Caml_option.some(JsxRuntime.jsx(CmsImage.make, {
                                src: img.url,
                                alt: img.description,
                                width: img.width,
                                height: img.height,
                                sizes: sizes
                              }));
              }));
}

function getImages(images) {
  return Belt_Array.mapWithIndex(images, (function (idx, img) {
                var key = String(idx);
                if (img.NAME === "DeviceSizeImage") {
                  var images = img.VAL;
                  var smartLink = images.smartLink;
                  var imageD = getAssetOption(images.desktop_image);
                  var imageT = getAssetOption(images.tablet_image);
                  var imageM = getAssetOption(images.mobile_image);
                  return JsxRuntime.jsx(KontentContextProvider.make, {
                              children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                                    children: JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx(DeviceSizeImage$PrezzeeRibbon.make, {
                                                desktop: imageD,
                                                smallScreen: imageT,
                                                tablet: undefined,
                                                mobile: imageM
                                              })
                                        })
                                  }),
                              itemId: smartLink.itemId,
                              elementCodename: smartLink.codename
                            }, key);
                }
                var img$1 = img.VAL;
                var smartLink$1 = img$1.smartLink;
                var width = img$1.width;
                return JsxRuntime.jsx(KontentContextProvider.make, {
                            children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                                  children: JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(CmsImage.make, {
                                              src: img$1.url,
                                              alt: img$1.description,
                                              width: width,
                                              height: img$1.height,
                                              className: cmsImg(width),
                                              sizes: "" + String(width) + "px"
                                            })
                                      })
                                }),
                            itemId: smartLink$1.itemId,
                            elementCodename: smartLink$1.codename
                          }, key);
              }));
}

var Helpers = {
  KontentContextConsumer: undefined,
  DeviceSizeImage: undefined,
  getImageOption: getImageOption,
  getAssetOption: getAssetOption,
  getImageOptionWithSizes: getImageOptionWithSizes,
  getAssetOptionWithSizes: getAssetOptionWithSizes,
  getImages: getImages
};

export {
  Styles ,
  Helpers ,
}
/* BsCss Not a pure module */
