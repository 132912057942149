import { useState, useEffect, RefObject } from 'react';

const defaultOptions = { attributes: true, childList: true, subtree: true };

export const useMutationObserver = (
  targetRef: RefObject<Node>,
  cb: MutationCallback,
  config: MutationObserverInit = defaultOptions
) => {
  const [observer, setObserver] = useState<MutationObserver>(null);

  useEffect(() => {
    const obs = new MutationObserver(cb);
    setObserver(obs);
  }, [cb, config, setObserver]);

  useEffect(() => {
    if (!observer || !targetRef.current) return;
    observer.observe(targetRef.current, config);

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [observer, targetRef, config]);
};
