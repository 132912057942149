// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as BsCss from "../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as React from "react";
import * as CmsImage from "../CmsImage.bs.js";
import Link from "next/link";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import Classnames from "classnames";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as IndexStyles from "../pages/index/IndexStyles.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Modal$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Modal/Modal.bs.js";
import * as Vimeo$PrezzeeRibbon from "@prezzee/ribbon/src/bindings/Vimeo.bs.js";
import * as Button$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Button/Button.bs.js";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as KontentContextProvider from "../pages/index/KontentContextProvider.bs.js";
import * as RichText$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RichText/RichText.bs.js";
import * as Modal__Close$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Modal/Modal__Close.bs.js";
import * as FullWidthHero$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/FullWidthHero/FullWidthHero.bs.js";
import * as Modal__Trigger$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Modal/Modal__Trigger.bs.js";
import * as SectionHeading$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/SectionHeading/SectionHeading.bs.js";
import * as TextTransition$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/TextTransition/TextTransition.bs.js";
import * as DeviceSizeImage$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/DeviceSizeImage/DeviceSizeImage.bs.js";
import * as Modal__Container$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Modal/Modal__Container.bs.js";
import * as Theme__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Responsive.bs.js";
import * as InjectableRichText$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RichText/InjectableRichText.bs.js";
import * as VimeoPlayer__Video$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/VimeoPlayer/VimeoPlayer__Video.bs.js";
import * as VimeoPlayer__Player$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/VimeoPlayer/VimeoPlayer__Player.bs.js";
import * as FullWidthHero__Image$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/FullWidthHero/FullWidthHero__Image.bs.js";
import * as FullWidthHero__CtaLine$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/FullWidthHero/FullWidthHero__CtaLine.bs.js";
import * as FullWidthHero__Heading$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/FullWidthHero/FullWidthHero__Heading.bs.js";
import * as FullWidthHero__BodyText$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/FullWidthHero/FullWidthHero__BodyText.bs.js";
import * as FullWidthHero__CtaLineVideo$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/FullWidthHero/FullWidthHero__CtaLineVideo.bs.js";
import * as FullWidthHero__TextContainer$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/FullWidthHero/FullWidthHero__TextContainer.bs.js";
import * as FullWidthHero__ImageContainer$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/FullWidthHero/FullWidthHero__ImageContainer.bs.js";
import * as SectionHeading__Theme__Business$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/SectionHeading/SectionHeading__Theme__Business.bs.js";
import * as FullWidthHero__CtaLineVideo__Theme__Business$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/FullWidthHero/FullWidthHero__CtaLineVideo__Theme__Business.bs.js";

var CtaLineVideo = FullWidthHero__CtaLineVideo$PrezzeeRibbon.Component(FullWidthHero__CtaLineVideo__Theme__Business$PrezzeeRibbon.Theme);

var SectionHeading = SectionHeading$PrezzeeRibbon.Component(SectionHeading__Theme__Business$PrezzeeRibbon.Theme);

var container = Curry._1(BsCss.style, {
      hd: BsCss.marginTop(BsCss.px(-67)),
      tl: {
        hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.belowLargeScreen, {
              hd: BsCss.important(BsCss.paddingTop(BsCss.px(74))),
              tl: {
                hd: BsCss.marginTop(BsCss.px(0)),
                tl: /* [] */0
              }
            }),
        tl: {
          hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.mobile, {
                hd: BsCss.important(BsCss.paddingTop(BsCss.px(20))),
                tl: {
                  hd: BsCss.important(BsCss.paddingBottom(BsCss.px(0))),
                  tl: /* [] */0
                }
              }),
          tl: /* [] */0
        }
      }
    });

var imageContainer = Curry._1(BsCss.style, {
      hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.desktop, {
            hd: BsCss.height(BsCss.pct(100)),
            tl: /* [] */0
          }),
      tl: {
        hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.smallScreenOrMobile, {
              hd: BsCss.selector("img", {
                    hd: BsCss.width(BsCss.pct(100)),
                    tl: {
                      hd: BsCss.height("auto"),
                      tl: {
                        hd: BsCss.position("absolute"),
                        tl: {
                          hd: BsCss.left("zero"),
                          tl: {
                            hd: BsCss.bottom("zero"),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }),
              tl: /* [] */0
            }),
        tl: {
          hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.mediaRange(Theme__Responsive$PrezzeeRibbon.smallScreenBreakpoint, Theme__Responsive$PrezzeeRibbon.tabletBreakpoint), {
                hd: BsCss.selector("img", {
                      hd: BsCss.right(BsCss.pct(-30)),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }),
          tl: {
            hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.mediaRange(Theme__Responsive$PrezzeeRibbon.tabletBreakpoint, Theme__Responsive$PrezzeeRibbon.largeScreenBreakpoint), {
                  hd: BsCss.selector("img", {
                        hd: BsCss.right(BsCss.pct(-25)),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var textContainer = Curry._1(BsCss.style, {
      hd: BsCss.important(BsCss.maxWidth(BsCss.px(1300))),
      tl: {
        hd: BsCss.important(BsCss.paddingRight(BsCss.rem(1.875))),
        tl: {
          hd: BsCss.important(BsCss.paddingLeft(BsCss.rem(1.875))),
          tl: {
            hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.mediaRange(Theme__Responsive$PrezzeeRibbon.tabletBreakpoint, Theme__Responsive$PrezzeeRibbon.largeScreenBreakpoint), {
                  hd: BsCss.children({
                        hd: BsCss.important(BsCss.maxWidth(BsCss.px(480))),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }),
            tl: {
              hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.smallScreen, {
                    hd: BsCss.children({
                          hd: BsCss.important(BsCss.maxWidth(BsCss.pct(100))),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: BsCss.important(BsCss.paddingBottom(BsCss.pct(50))),
                      tl: /* [] */0
                    }
                  }),
              tl: {
                hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.mobile, {
                      hd: BsCss.important(BsCss.paddingBottom(BsCss.pct(70))),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var bodyText = Curry._1(BsCss.style, {
      hd: BsCss.important(BsCss.maxWidth(BsCss.px(512))),
      tl: /* [] */0
    });

var Styles = {
  container: container,
  imageContainer: imageContainer,
  textContainer: textContainer,
  bodyText: bodyText
};

function LandingPage__FullWidthHero$VideoModal(props) {
  var match = React.useState(function () {
        return false;
      });
  var setModalOpen = match[1];
  var onRequestClose = function (param) {
    Curry._1(setModalOpen, (function (param) {
            return false;
          }));
  };
  var openFunc = function (param) {
    Curry._1(setModalOpen, (function (param) {
            return true;
          }));
  };
  var playerOptions = Vimeo$PrezzeeRibbon.Player.options(undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined);
  var content = JsxRuntime.jsx("div", {
        children: JsxRuntime.jsx(VimeoPlayer__Video$PrezzeeRibbon.make, {
              videoUrl: props.url,
              children: JsxRuntime.jsx(VimeoPlayer__Player$PrezzeeRibbon.make, {
                    id: "video-modal-story",
                    playerOptions: playerOptions
                  })
            })
      });
  var iconImage = Belt_Option.mapWithDefault(props.icon, null, (function (param) {
          return JsxRuntime.jsx(CmsImage.make, {
                      src: param.url,
                      alt: param.description,
                      width: param.width,
                      height: param.height
                    });
        }));
  return JsxRuntime.jsxs(Modal$PrezzeeRibbon.make, {
              children: [
                JsxRuntime.jsx(Modal__Trigger$PrezzeeRibbon.make, {
                      children: JsxRuntime.jsxs(JsxRuntime.Fragment, {
                            children: [
                              iconImage,
                              props.text
                            ]
                          }),
                      openFunc: openFunc
                    }),
                JsxRuntime.jsxs(Modal__Container$PrezzeeRibbon.make, {
                      id: "modal-container",
                      isOpen: match[0],
                      onRequestClose: onRequestClose,
                      children: [
                        JsxRuntime.jsx(Modal__Close$PrezzeeRibbon.make, {
                              closeFunc: onRequestClose
                            }),
                        content
                      ],
                      borderless: true
                    })
              ]
            });
}

var VideoModal = {
  make: LandingPage__FullWidthHero$VideoModal
};

function LandingPage__FullWidthHero(props) {
  var block = props.block;
  var smartLink = block.smartLink;
  var video_button_url = block.video_button_url;
  var scrolling_text = block.scrolling_text;
  var title = block.title;
  var bgDesktop = Belt_Option.mapWithDefault(block.desktop_image, undefined, (function (param) {
          return Caml_option.some(JsxRuntime.jsx(CmsImage.make, {
                          src: param.url,
                          alt: param.description,
                          width: param.width,
                          height: param.height,
                          sizes: "" + Theme__Responsive$PrezzeeRibbon.tabletOrMobile + " 388px, " + Theme__Responsive$PrezzeeRibbon.largeScreen + " 631px, 497px",
                          loading: "eager"
                        }));
        }));
  var bgTablet = Belt_Option.mapWithDefault(block.tablet_image, undefined, (function (param) {
          return Caml_option.some(JsxRuntime.jsx(CmsImage.make, {
                          src: param.url,
                          alt: param.description,
                          width: param.width,
                          height: param.height,
                          sizes: "100vw",
                          loading: "eager"
                        }));
        }));
  var bgMobile = Belt_Option.mapWithDefault(block.mobile_image, undefined, (function (param) {
          return Caml_option.some(JsxRuntime.jsx(CmsImage.make, {
                          src: param.url,
                          alt: param.description,
                          width: param.width,
                          height: param.height,
                          sizes: "100vw",
                          loading: "eager"
                        }));
        }));
  var bgImages = JsxRuntime.jsx(DeviceSizeImage$PrezzeeRibbon.make, {
        desktop: bgDesktop,
        smallScreen: bgDesktop,
        tablet: bgTablet,
        mobile: bgMobile
      });
  var bgColourStyle = Belt_Option.mapWithDefault(block.background_colour, "", (function (bg) {
          return Curry._1(Css.style, {
                      hd: Css.unsafe("backgroundColor", bg),
                      tl: /* [] */0
                    });
        }));
  var content;
  if (scrolling_text !== undefined) {
    var textArr = Belt_Array.map(scrolling_text.items, (function (c) {
            return c.text;
          }));
    var injectItem_1 = JsxRuntime.jsx(TextTransition$PrezzeeRibbon.make, {
          textArr: textArr,
          inline: true,
          noOverflow: true
        });
    var injectItem = [
      "strong",
      injectItem_1
    ];
    var injectArr = [injectItem];
    content = JsxRuntime.jsx(InjectableRichText$PrezzeeRibbon.make, {
          children: title,
          injectArr: injectArr
        });
  } else {
    content = JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
          children: title
        });
  }
  var heading = JsxRuntime.jsx(FullWidthHero__Heading$PrezzeeRibbon.make, {
        children: JsxRuntime.jsx(SectionHeading.make, {
              children: content
            })
      });
  var bodyText$1 = JsxRuntime.jsx(FullWidthHero__BodyText$PrezzeeRibbon.make, {
        children: JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
              children: block.description
            }),
        className: bodyText
      });
  var ctaButtons = Belt_Array.mapWithIndex(block.buttons, (function (i, b) {
          var target = b.open_in_new_window ? "_blank" : "";
          var match = b.smartLink;
          return JsxRuntime.jsx(KontentContextProvider.make, {
                      children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                            children: JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(Button$PrezzeeRibbon.make, {
                                        children: JsxRuntime.jsx(Link, {
                                              href: b.cta_url,
                                              prefetch: false,
                                              children: JsxRuntime.jsx("a", {
                                                    children: JsxRuntime.jsx("button", {
                                                          children: b.cta_text
                                                        }),
                                                    target: target
                                                  })
                                            }),
                                        fill: b.fill,
                                        colour: b.colour,
                                        variant: b.variant
                                      }, String(i))
                                })
                          }),
                      itemId: match.itemId,
                      elementCodename: match.codename
                    }, String(i));
        }));
  var ctaLine = JsxRuntime.jsx(FullWidthHero__CtaLine$PrezzeeRibbon.make, {
        children: ctaButtons
      });
  var ctaLineVideo = video_button_url === "" ? null : JsxRuntime.jsx(CtaLineVideo.make, {
          children: JsxRuntime.jsx(LandingPage__FullWidthHero$VideoModal, {
                text: block.video_button_text,
                icon: block.video_button_icon,
                url: video_button_url
              })
        });
  return JsxRuntime.jsxs(FullWidthHero$PrezzeeRibbon.make, {
              classname: Classnames([
                    container,
                    bgColourStyle
                  ]),
              children: [
                JsxRuntime.jsx(FullWidthHero__TextContainer$PrezzeeRibbon.make, {
                      children: JsxRuntime.jsx(KontentContextProvider.make, {
                            children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                                  children: JsxRuntime.jsxs("div", {
                                        children: [
                                          heading,
                                          bodyText$1,
                                          ctaLine,
                                          ctaLineVideo
                                        ]
                                      })
                                }),
                            itemId: smartLink.itemId,
                            elementCodename: smartLink.codename
                          }),
                      className: Cn.make([
                            IndexStyles.section,
                            textContainer
                          ])
                    }),
                JsxRuntime.jsx(FullWidthHero__ImageContainer$PrezzeeRibbon.make, {
                      children: JsxRuntime.jsx(FullWidthHero__Image$PrezzeeRibbon.make, {
                            children: bgImages,
                            className: imageContainer
                          })
                    })
              ]
            });
}

var ImageContainer;

var HeroImage;

var TextContainer;

var Heading;

var BodyText;

var CtaLine;

var KontentContextConsumer;

var make = LandingPage__FullWidthHero;

export {
  ImageContainer ,
  HeroImage ,
  TextContainer ,
  Heading ,
  BodyText ,
  CtaLine ,
  CtaLineVideo ,
  KontentContextConsumer ,
  SectionHeading ,
  Styles ,
  VideoModal ,
  make ,
}
/* CtaLineVideo Not a pure module */
