import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { section as indexSection } from './pages/index/IndexStyles.bs';
import { css } from '@emotion/css';

import colors from '@prezzee/ribbon-ui/lib/theme/foundations/colors';
import { desktop, tabletOrMobile, tabletSm } from '@prezzee/ribbon/src/theme/Theme__Responsive.bs';

import { useMutationObserver } from 'utils/MutationObserverHook';
import useSmartLink from 'hooks/useSmartLink';
import { t as SmartLink } from './library/SmartLink.gen';
import Script from 'next/script';

interface Props {
  block: {
    title_text: string;
    title_alignment: string;
    title_heading: 'h2' | 'h3' | 'h4';
    widget_code: string;
    bottom_distance?: number;
    top_distance?: number;
    smartLink: SmartLink;
  };
}

const Styles = {
  section: (top_distance, bottom_distance, hidden) => css`
    display: ${hidden ? 'none' : 'block'};
    margin-top: ${top_distance ? `${top_distance}px` : `0.75rem`};
    margin-bottom: ${bottom_distance ? `${bottom_distance}px` : `2.5rem`};
  `,
  header: aligment => css`
    color: ${colors.gray[800]};
    text-align: ${aligment};
    font-weight: 600;

    @media ${desktop} {
      margin-bottom: 2rem;
    }

    @media ${tabletOrMobile} {
      margin-bottom: 1.875rem;
    }
  `,
  h2: css`
    font-size: 40px;
    line-height: 50px;

    @media ${tabletSm} {
      font-size: 32px;
      line-height: 40px;
    }
  `,
  h3: css`
    font-size: 36px;
    line-height: 46px;

    @media ${tabletSm} {
      font-size: 32px;
      line-height: 42px;
    }
  `,
  h4: css`
    font-size: 32px;
    line-height: 42px;

    @media ${tabletSm} {
      font-size: 28px;
      line-height: 38px;
    }
  `,
};

const headerElements = {
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
};

const TrustpilotWidgetSection: FC<Props> = ({ block }) => {
  const { title_text, title_alignment, title_heading, widget_code, smartLink } = block;
  const spotlightProps = useSmartLink(smartLink);

  const widgetRef = useRef(null);
  const [isHidden, setHidden] = useState(true);

  const headerTag = headerElements[title_heading] ? headerElements[title_heading] : 'h2';
  const Heading = headerTag as keyof JSX.IntrinsicElements;

  const onMutationChange = useCallback((mutation, observer) => {
    if (widgetRef.current && widgetRef.current.querySelector('iframe')) {
      setHidden(false);
      observer.disconnect();
    }
  }, []);
  useEffect(() => {
    if (widgetRef.current && widgetRef.current.querySelector('iframe')) {
      setHidden(false);
    } else if (widgetRef.current && window.Trustpilot) {
      window.Trustpilot.loadFromElement(widgetRef.current.querySelector('div'));
      setHidden(false);
    }
  }, []);

  useMutationObserver(widgetRef, onMutationChange);

  return (
    <>
      <Script
        type="text/javascript"
        src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        async
      />
      <section
        className={`${indexSection} ${Styles.section(block.top_distance, block.bottom_distance, isHidden)}`}
        {...spotlightProps}
      >
        {title_text !== '' ? (
          <Heading className={`${Styles[title_heading]} ${Styles.header(title_alignment)}`}>{title_text}</Heading>
        ) : null}
        <div ref={widgetRef} dangerouslySetInnerHTML={{ __html: widget_code }} />
      </section>
    </>
  );
};

export default TrustpilotWidgetSection;
