import { FC } from 'react';
import Link from 'next/link';
import { css } from '@emotion/css';

import { t as CategoryTileBlock_t } from 'api/graphql/CategoryTiles/Gql__CategoryTiles__Decoder__CategoryTileBlock.gen';
import { t as CategoryTile_t } from 'api/graphql/CategoryTiles/Gql__CategoryTiles__Decoder__CategoryTile.gen';
import { make as CategoryTiles } from '@prezzee/ribbon/src/components/common/CategoryTiles/CategoryTiles.gen';
import { make as Single } from '@prezzee/ribbon/src/components/common/CategoryTiles/CategoryTiles__Single.gen';
import { make as Button } from '@prezzee/ribbon/src/components/personal/Button/Button.gen';
import { desktop, tablet, mobile, tabletOrDesktop } from '@prezzee/ribbon/src/theme/Theme__Responsive.bs.js';
import useSmartLink from 'hooks/useSmartLink';
import CmsImage from 'components/CmsImage';
import { Color } from 'css/theme';
import { EMPTY_ELEMENT } from 'common/utils/react';

const classes = {
  section: css`
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;

    @media ${desktop} {
      margin-top: 6.5rem;
      margin-bottom: 7.5rem;
    }

    @media ${tabletOrDesktop} {
      padding-left: 1.875rem !important;
      padding-right: 1.875rem !important;
    }

    @media ${tablet} {
      margin-top: 4.5rem;
      margin-bottom: 3rem;
    }

    @media ${mobile} {
      margin: 3.75rem 1.25rem;
    }
  `,
  heading: css`
    font-weight: 600;
    color: ${Color.Gray800};

    @media ${desktop} {
      font-size: 40px;
      line-height: 40px;
      margin-bottom: 2rem;
    }

    @media ${tablet} {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 1.875rem;
    }

    @media ${mobile} {
      font-size: 28px;
      line-height: 38px;
      margin-bottom: 0.5rem;
    }
  `,
};

type CtaProps = {
  button: CategoryTileBlock_t['button'];
};

type CategoryTilesProps = {
  block: CategoryTileBlock_t;
};

type TileProps = {
  tile: CategoryTile_t;
};

const Cta: FC<CtaProps> = ({ button }) => {
  const { variant, fill, colour, cta_url, cta_text, open_in_new_window, smartLink } = button;
  const spotlightProps = useSmartLink(smartLink);

  return (
    <Button className={'mt-8'} variant={variant} fill={fill} colour={colour}>
      <Link href={cta_url} prefetch={false}>
        <a target={open_in_new_window ? '_blank' : ''}>
          <button {...spotlightProps}>{cta_text}</button>
        </a>
      </Link>
    </Button>
  );
};

const Tile: FC<TileProps> = ({ tile }) => {
  const { title, link, image, labelBackgroundColor, smartLink } = tile;
  const spotlightProps = useSmartLink(smartLink);

  return (
    <>
      <Link href={link} prefetch={false}>
        <a {...spotlightProps}>
          <Single text={title} labelBackgroundColor={labelBackgroundColor.length ? labelBackgroundColor : void 0}>
            <CmsImage
              src={image.url}
              alt={image.description}
              width={image.width}
              height={image.height}
              sizes={`${mobile} 44vw, 290px}`}
            />
          </Single>
        </a>
      </Link>
    </>
  );
};

const CategoryTilesSection: FC<CategoryTilesProps> = ({ block }) => {
  const { tiles, title, button, smartLink } = block;
  const spotlightProps = useSmartLink(smartLink);

  return (
    <section className={classes.section} {...spotlightProps}>
      <h2 className={classes.heading}>{title}</h2>
      {/* @ts-ignore */}
      <CategoryTiles>{tiles.map((t, idx) => (t.image ? <Tile tile={t} key={idx} /> : EMPTY_ELEMENT))}</CategoryTiles>
      {button ? <Cta button={button} /> : EMPTY_ELEMENT}
    </section>
  );
};

export default CategoryTilesSection;
