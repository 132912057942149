// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as IndexStyles from "./IndexStyles.bs.js";
import * as LayoutDataContext from "../../../utils/LayoutDataContext.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as KontentContextProvider from "./KontentContextProvider.bs.js";
import * as RichText$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RichText/RichText.bs.js";
import * as Accordion$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Accordion/Accordion.bs.js";
import * as ThemeContext$PrezzeeRibbon from "@prezzee/ribbon/src/context/ThemeContext/ThemeContext.bs.js";
import * as Accordion__Panel$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Accordion/Accordion__Panel.bs.js";
import * as Theme__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Responsive.bs.js";
import * as Accordion__Heading$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Accordion/Accordion__Heading.bs.js";
import * as Accordion__Trigger$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Accordion/Accordion__Trigger.bs.js";

function container(bgColor) {
  return Curry._1(BsCss.style, {
              hd: BsCss.backgroundColor(bgColor),
              tl: {
                hd: BsCss.paddingTop(BsCss.rem(0.25)),
                tl: {
                  hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.tablet, {
                        hd: BsCss.paddingTop(BsCss.rem(2.8125)),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.tabletSm, {
                          hd: BsCss.paddingTop(BsCss.rem(0.25)),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: BsCss.selector("button:focus", {
                            hd: BsCss.outlineWidth("zero"),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

var panel = Curry._1(BsCss.style, {
      hd: BsCss.fontSize(BsCss.px(14)),
      tl: {
        hd: BsCss.lineHeight(BsCss.px(20)),
        tl: {
          hd: BsCss.selector("p", {
                hd: BsCss.marginBottom(BsCss.em(1.2)),
                tl: /* [] */0
              }),
          tl: {
            hd: BsCss.selector("p:last-of-type", {
                  hd: BsCss.marginBottom("zero"),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var SectionStyles = {
  container: container,
  panel: panel
};

function FullWidthTextSection$AccordionBlock(props) {
  return JsxRuntime.jsxs(Accordion$PrezzeeRibbon.make, {
              children: [
                JsxRuntime.jsx(Accordion__Heading$PrezzeeRibbon.make, {
                      children: JsxRuntime.jsx(Accordion__Trigger$PrezzeeRibbon.make, {
                            children: props.heading
                          })
                    }),
                JsxRuntime.jsx(Accordion__Panel$PrezzeeRibbon.make, {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
                                  children: props.description
                                }),
                            className: panel
                          })
                    })
              ],
              colour: /* White */2
            });
}

var AccordionBlock = {
  themeColour: /* White */2,
  make: FullWidthTextSection$AccordionBlock
};

function FullWidthTextSection(props) {
  var block = props.block;
  var smartLink = block.smartLink;
  var theme = ThemeContext$PrezzeeRibbon.useTheme(undefined);
  var state = LayoutDataContext.useContext(undefined);
  var footer = Belt_Option.flatMap(state, (function (s) {
          return s.footer;
        }));
  var bgColor = Belt_Option.mapWithDefault(footer, theme.components.footer.backgroundColor, (function (s) {
          return s.theme.backgroundColor;
        }));
  return JsxRuntime.jsx("section", {
              children: JsxRuntime.jsx(KontentContextProvider.make, {
                    children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                          children: JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx(FullWidthTextSection$AccordionBlock, {
                                      heading: block.title,
                                      description: block.description
                                    }),
                                className: IndexStyles.section
                              })
                        }),
                    itemId: smartLink.itemId,
                    elementCodename: smartLink.codename
                  }),
              className: container(bgColor)
            });
}

var KontentContextConsumer;

var make = FullWidthTextSection;

export {
  KontentContextConsumer ,
  SectionStyles ,
  AccordionBlock ,
  make ,
}
/* panel Not a pure module */
