// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as CmsImage from "../../CmsImage.bs.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as IndexStyles from "./IndexStyles.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as Tagline$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Tagline/Tagline.bs.js";
import * as KontentContextProvider from "./KontentContextProvider.bs.js";
import * as RichText$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RichText/RichText.bs.js";
import * as Theme__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Responsive.bs.js";
import * as UniqueSellingPoints$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/UniqueSellingPoints/UniqueSellingPoints.bs.js";
import * as UniqueSellingPoints__List$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/UniqueSellingPoints/UniqueSellingPoints__List.bs.js";
import * as UniqueSellingPoints__Heading$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/UniqueSellingPoints/UniqueSellingPoints__Heading.bs.js";
import * as UniqueSellingPoints__ListItem$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/UniqueSellingPoints/UniqueSellingPoints__ListItem.bs.js";
import * as UniqueSellingPoints__Description$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/UniqueSellingPoints/UniqueSellingPoints__Description.bs.js";
import * as UniqueSellingPoints__TextContainer$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/UniqueSellingPoints/UniqueSellingPoints__TextContainer.bs.js";
import * as UniqueSellingPoints__ImageContainer$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/UniqueSellingPoints/UniqueSellingPoints__ImageContainer.bs.js";

var section = Curry._1(BsCss.style, {
      hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.desktop, {
            hd: BsCss.marginTop(BsCss.rem(7.5)),
            tl: {
              hd: BsCss.marginBottom(BsCss.rem(8.4375)),
              tl: /* [] */0
            }
          }),
      tl: {
        hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.tablet, {
              hd: BsCss.marginTop(BsCss.rem(3)),
              tl: {
                hd: BsCss.marginBottom(BsCss.rem(8.875)),
                tl: /* [] */0
              }
            }),
        tl: {
          hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.mobile, {
                hd: BsCss.marginTop(BsCss.rem(3.6875)),
                tl: {
                  hd: BsCss.marginBottom(BsCss.rem(4.6875)),
                  tl: /* [] */0
                }
              }),
          tl: /* [] */0
        }
      }
    });

var Styles = {
  section: section
};

function UniqueSellingPointsSection$SideImage(props) {
  var backgroundImage = props.backgroundImage;
  if (backgroundImage !== undefined) {
    return JsxRuntime.jsx(CmsImage.make, {
                src: backgroundImage.url,
                alt: backgroundImage.description,
                width: backgroundImage.width,
                height: backgroundImage.height,
                sizes: "" + Theme__Responsive$PrezzeeRibbon.tabletSm + " 100vw, " + Theme__Responsive$PrezzeeRibbon.tabletOrMobile + " 48vw, " + Theme__Responsive$PrezzeeRibbon.belowLargeScreen + " 44vw, 544px"
              });
  } else {
    return null;
  }
}

var SideImage = {
  make: UniqueSellingPointsSection$SideImage
};

function getUniqueSellingPointItems(items) {
  return Belt_Array.mapWithIndex(items, (function (idx, item) {
                var key = String(idx);
                var match = item.smartLink;
                return JsxRuntime.jsx(KontentContextProvider.make, {
                            children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                                  children: JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(UniqueSellingPoints__ListItem$PrezzeeRibbon.make, {
                                              children: item.title
                                            })
                                      })
                                }),
                            itemId: match.itemId,
                            elementCodename: match.codename
                          }, key);
              }));
}

var Content = {
  getUniqueSellingPointItems: getUniqueSellingPointItems
};

function UniqueSellingPointsSection(props) {
  var block = props.block;
  var heading = JsxRuntime.jsx("h2", {
        children: block.title
      });
  var description = JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
        children: block.description
      });
  var image = JsxRuntime.jsx(UniqueSellingPointsSection$SideImage, {
        backgroundImage: block.background_image
      });
  var uniqueSellingPointItems = getUniqueSellingPointItems(block.unique_selling_points);
  var match = block.smartLink;
  return JsxRuntime.jsx("section", {
              children: JsxRuntime.jsx(KontentContextProvider.make, {
                    children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                          children: JsxRuntime.jsxs(UniqueSellingPoints$PrezzeeRibbon.make, {
                                children: [
                                  JsxRuntime.jsxs(UniqueSellingPoints__TextContainer$PrezzeeRibbon.make, {
                                        children: [
                                          JsxRuntime.jsx(Tagline$PrezzeeRibbon.make, {
                                                children: block.tagline
                                              }),
                                          JsxRuntime.jsx(UniqueSellingPoints__Heading$PrezzeeRibbon.make, {
                                                children: heading
                                              }),
                                          JsxRuntime.jsx(UniqueSellingPoints__Description$PrezzeeRibbon.make, {
                                                children: description
                                              }),
                                          JsxRuntime.jsx(UniqueSellingPoints__List$PrezzeeRibbon.make, {
                                                children: uniqueSellingPointItems
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsx(UniqueSellingPoints__ImageContainer$PrezzeeRibbon.make, {
                                        children: image
                                      })
                                ]
                              })
                        }),
                    itemId: match.itemId,
                    elementCodename: match.codename
                  }),
              className: Cn.make([
                    IndexStyles.section,
                    section
                  ])
            });
}

var UniqueSellingPoints;

var TextContainer;

var Tagline;

var Heading;

var Description;

var List;

var ListItem;

var ImageContainer;

var RichText;

var KontentContextConsumer;

var make = UniqueSellingPointsSection;

export {
  UniqueSellingPoints ,
  TextContainer ,
  Tagline ,
  Heading ,
  Description ,
  List ,
  ListItem ,
  ImageContainer ,
  RichText ,
  KontentContextConsumer ,
  Styles ,
  SideImage ,
  Content ,
  make ,
}
/* section Not a pure module */
